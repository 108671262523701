import { useAppSelector } from "@/redux/store";

type Props = {
  text: string;
  onClick: () => void;
  width?: string;
  height?: string;
  bgColor?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string;
  py?: string;
  px?: string;
  outline?: string;
  iconLeft?: any;
  iconRight?: any;
  gap?: string;
  borderRadius?: string;
  disabled?: boolean;
};

const RoundButton = ({
  text,
  onClick,
  width,
  height,
  bgColor,
  textColor,
  fontSize,
  fontWeight,
  py,
  px,
  outline,
  iconLeft,
  iconRight,
  gap,
  borderRadius,
  disabled,
}: Props) => {
  const { primary } = useAppSelector((state) => state.setting.dynamicColor);

  return (
    <div
      className={`${disabled ? "text-center opacity-50" : "hover-scale-sm cursor-pointer"} px-${px ?? "3"} py-${py ?? "2"} ${bgColor ?? "bg-primary"}`}
      style={{
        width: width ?? "fit-content",
        height: height ?? "fit-content",
        outline: outline ?? "",
        display: iconLeft || iconRight ? "flex" : "inline",
        justifyContent: iconLeft || iconRight ? "center" : "",
        alignItems: iconLeft || iconRight ? "center" : "",
        gap: gap ?? "6px",
        borderRadius: borderRadius ?? "6px",
      }}
      onClick={disabled ? () => {} : onClick}
    >
      {iconLeft}
      <p
        className={`font-dm-sans ${textColor ? textColor : "text-white"}`}
        style={{ fontSize: fontSize ?? "14px", fontWeight: fontWeight ?? "500" }}
      >
        {text}
      </p>
      {iconRight}
    </div>
  );
};

export default RoundButton;
