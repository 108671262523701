import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as CreateEmployeeIcon } from "@/assets/icons/svg/createEmployeeIcon.svg";
import { ReactComponent as TableSortIcon } from "@/assets/icons/svg/tableSortIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Avatar, Badge, Table, Tooltip } from "flowbite-react";
import userDefaultAvatar from "@/assets/defaults/userDefaultAvatar.png";
import { useTranslation } from "react-i18next";
import Pagination from "@/components/utils/Pagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { linkedSchemeTooltip } from "@/flowbiteThemes/tooltipTheme";
import { handleCaughtError, snakeCaseToCamelCase, updateQueryParam } from "@/utils/functions";
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";
import { getClientList } from "@/api";

const Client = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const tableHeadCells = [
    "client_code",
    "client_name",
    "contact_person",
    "contact_number",
    "email_address",
    "linked_scheme",
    "action",
  ];

  const linkedSchemeData = [
    "Achievement Reward",
    "Team Performance Bonus",
    "3Achievement Reward",
    "4Achievement Reward",
    "5Achievement Reward",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [currentSortColumn, setCurrentSortColumn] = useState<SortColumn>("");
  const [currentSortOrder, setCurrentSortOrder] = useState<SortOrder>("desc");
  // fetch get client list
  const [getClientListResponse, setGetClientListResponse] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  console.log("getClientListResponse:", getClientListResponse);

  const fetchGetClientList = async () => {
    const page = currentPage - 1;
    const sortColumn = snakeCaseToCamelCase(currentSortColumn);
    try {
      const response = await getClientList(page, currentLimit, sortColumn, currentSortOrder, currentSearchValue);
      if (response) {
        setGetClientListResponse(response.data);
      }
    } catch (error) {
      console.error(error);
      updateQueryParam("status", "error");
      setErrorMessage(handleCaughtError(error));
    }
  };

  // Debounce logic for the search value
  const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(currentSearchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [currentSearchValue]);

  // Fetch employee list whenever page, limit, sort, or debounced search value changes
  useEffect(() => {
    fetchGetClientList();
  }, [currentPage, currentLimit, currentSortColumn, currentSortOrder, debouncedSearchValue, i18n.language]);

  const remainLinkedSchemeTooltip = (data: string[]) => {
    const newArr = data.slice(2);
    return (
      <div className="text-dark flex items-center gap-1.5 text-[10px] font-semibold">
        {newArr.map((item, index) => {
          return (
            <figure key={index} className="flex h-9 items-center whitespace-nowrap rounded-[3px] bg-[#0306230D] px-1.5">
              <p>{item}</p>
            </figure>
          );
        })}
      </div>
    );
  };

  return (
    <div id="client-main" className="h-full w-full">
      <header id="client-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={() => {}} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          width="118px"
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("client.create_client")}
          onClick={() => navigate(`${location.pathname}/create-client`)}
          width="200px"
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CreateEmployeeIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="mt-6 overflow-x-auto">
        <Table theme={theme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <Table.HeadCell key={index} className="bg-primary text-base font-medium text-white">
                  <div className="flex items-center gap-2">
                    <p>{t(`client.${tableHeadCell}`)}</p>
                    {tableHeadCell !== "action" && (
                      <figure className="hover-scale-sm cursor-pointer">
                        <SVGIcon icon={TableSortIcon} fill="#ffffff" width="24" height="24" />
                      </figure>
                    )}
                  </div>
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="text-dark h-[83px] border-b border-b-[#71707180] bg-white text-sm font-medium">
              <Table.Cell id="client_code">CC010</Table.Cell>
              <Table.Cell id="client_name">HealthFirst Medical Supply</Table.Cell>
              <Table.Cell id="contact_person">Law Kei To Torres</Table.Cell>
              <Table.Cell id="contact_number">+852 9123 4567</Table.Cell>
              <Table.Cell id="email_address">torres@healthfirst.com</Table.Cell>
              <Table.Cell id="linked_scheme">
                <div className="text-dark flex w-fit items-center gap-1.5">
                  {linkedSchemeData.slice(0, 2).map((item, index) => {
                    return (
                      <figure
                        key={index}
                        className=" flex h-9 w-fit items-center whitespace-nowrap rounded-[3px] bg-[#0306230D] px-1.5 text-[10px] font-semibold"
                      >
                        <p>{item}</p>
                      </figure>
                    );
                  })}
                  <Tooltip
                    content={remainLinkedSchemeTooltip(linkedSchemeData)}
                    style="light"
                    theme={linkedSchemeTooltip}
                    arrow={false}
                  >
                    {linkedSchemeData.length > 2 && (
                      <figure className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-[3px] bg-[#0306230D] text-sm font-semibold opacity-60">
                        <p>{`+${linkedSchemeData.length - 2}`}</p>
                      </figure>
                    )}
                  </Tooltip>
                </div>
              </Table.Cell>
              <Table.Cell id="action">
                <div className="flex gap-3">
                  <Badge className="hover-scale-sm flex h-9 min-w-fit cursor-pointer items-center justify-center rounded-[6px] bg-[#8338EC26] p-3 text-sm font-medium text-[#8338EC]">
                    {t("common.details")}
                  </Badge>
                  <Badge className="hover-scale-sm flex h-9 min-w-fit cursor-pointer items-center justify-center rounded-[6px] bg-[#F23A5A26] p-3 text-sm font-medium text-[#F23A5A]">
                    {t("common.delete")}
                  </Badge>
                </div>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        {/* <Pagination currentPage={currentPage} totalPages={10} onPageChange={(page: number) => setCurrentPage(page)} /> */}
      </footer>
    </div>
  );
};
export default Client;

const theme = {
  root: {
    base: "w-full text-left text-sm text-gray-500",
    shadow: "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md",
    wrapper: "relative",
  },
  body: {
    base: "group/body",
    cell: {
      base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
    },
  },
  head: {
    base: "group/head text-xs text-gray-700",
    cell: {
      base: "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:first:rounded-bl-xl group-first/head:last:rounded-tr-xl group-first/head:last:rounded-br-xl",
    },
  },
  row: {
    base: "group/row",
    hovered: "hover:bg-gray-50",
    striped: "odd:bg-white even:bg-gray-50",
  },
};
