import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as TableSortIcon } from "@/assets/icons/svg/tableSortIcon.svg";
import { ReactComponent as CircleArrowRightIcon } from "@/assets/icons/svg/arrows/circleArrow/circleArrowRightIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Badge, Table } from "flowbite-react";
import { useTranslation } from "react-i18next";
import Pagination from "@/components/utils/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationToast from "@/components/toasts/NotificationToast";
import { HolidayType } from "@/types/enums";

const HolidayRecord = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const tableHeadCells = ["holiday_name", "holiday_type", "start_date", "end_date", "action"];

  //handle holiday type badge styles
  const handleLeaveTypeBadgeStyles = (type: HolidayType) => {
    const typeStylesMapping = {
      STATUTORY: { backgroundColor: "#E509140D", color: "#E50914" },
      COMPANY: { backgroundColor: "#AA4FF60D", color: "#AA4FF6" },
      OTHER: { backgroundColor: "#2778930D", color: "#277893" },
    };
    return typeStylesMapping[type];
  };

  return (
    <div id="HolidayRecord-main" className="h-full w-full">
      {/* <NotificationToast message={t("holiday.saved_success_message")} /> */}
      <header id="HolidayRecord-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={() => {}} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          width="118px"
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("holiday.create_holiday_record")}
          onClick={() => navigate(`${location.pathname}/create-holiday-record`)}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CircleArrowRightIcon} fill="#030623" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="mt-6 overflow-x-auto">
        <Table theme={theme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <Table.HeadCell key={index} className="bg-primary text-base font-medium text-white">
                  <div className="flex items-center gap-2">
                    <p>{t(`holiday.${tableHeadCell}`)}</p>
                    {tableHeadCell !== "action" && (
                      <figure className="hover-scale-sm cursor-pointer">
                        <SVGIcon icon={TableSortIcon} fill="#ffffff" width="24" height="24" />
                      </figure>
                    )}
                  </div>
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="text-dark h-[83px] border-b border-b-[#71707180] bg-white text-sm font-medium">
              <Table.Cell id="holiday_name" className="whitespace-nowrap">
                New Year’s Day
              </Table.Cell>
              <Table.Cell id="holiday_type">
                <Badge
                  className="flex h-9 w-fit items-center justify-center rounded-[3px] px-1.5 py-3 text-sm font-semibold"
                  style={handleLeaveTypeBadgeStyles(HolidayType.COMPANY)}
                >
                  {t("holiday.company")}
                </Badge>
              </Table.Cell>
              <Table.Cell id="start_date">2024/09/01</Table.Cell>
              <Table.Cell id="end_date">2024/09/05</Table.Cell>
              <Table.Cell id="action">
                <div className="flex gap-3">
                  <Badge className="hover-scale-sm flex h-9 w-fit cursor-pointer items-center justify-center rounded-[6px] bg-[#8338EC26] p-3 text-sm font-medium text-[#8338EC]">
                    {t("common.view_details")}
                  </Badge>
                  <Badge className="hover-scale-sm flex h-9 w-fit cursor-pointer items-center justify-center rounded-[6px] bg-[#F23A5A26] p-3 text-sm font-medium text-[#F23A5A]">
                    {t("common.delete")}
                  </Badge>
                </div>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        {/* <Pagination currentPage={currentPage} totalPages={10} onPageChange={(page: number) => setCurrentPage(page)} /> */}
      </footer>
    </div>
  );
};
export default HolidayRecord;

const theme = {
  root: {
    base: "w-full text-left text-sm text-gray-500",
    shadow: "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md",
    wrapper: "relative",
  },
  body: {
    base: "group/body",
    cell: {
      base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
    },
  },
  head: {
    base: "group/head text-xs text-gray-700",
    cell: {
      base: "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:first:rounded-bl-xl group-first/head:last:rounded-tr-xl group-first/head:last:rounded-br-xl",
    },
  },
  row: {
    base: "group/row",
    hovered: "hover:bg-gray-50",
    striped: "odd:bg-white even:bg-gray-50",
  },
};
