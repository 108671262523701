import TitleButton from "@/components/buttons/TitleButton";
import { useTranslation } from "react-i18next";
import RoundButton from "@/components/buttons/RoundButton";
import { ReactComponent as BackIcon } from "@/assets/icons/svg/backIcon.svg";
import { ReactComponent as NextIcon } from "@/assets/icons/svg/nextIcon.svg";
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as AddItemsIcon } from "@/assets/icons/svg/addItemsIcon.svg";
import { ReactComponent as BinIcon } from "@/assets/icons/svg/binIcon.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/svg/crossIcon.svg";
import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import { ReactComponent as DatePickerIcon } from "@/assets/icons/svg/datePickerIcon.svg";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Checkbox, Datepicker, Dropdown, FloatingLabel } from "flowbite-react";
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { ChangeEvent, useEffect, useState } from "react";
import { datePickerTheme } from "@/flowbiteThemes/datePickerTheme";
import CustomDatePicker from "@/components/pickers/CustomDatePicker";
import { format } from "date-fns";
import { formatDateByHyphen, formatDateBySlashYYYYMMDD } from "@/utils/functions";
import { Cycle, Gender } from "@/types/enums";
import { HiOutlineX } from "react-icons/hi";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

type InitialCommissionRangesItem = {
  min: number | null;
  max: number | null;
  percentage: number | null;
  bonus: number | null;
};

const CreateCommissionScheme = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  //input focus
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [selectedInputType, setSelectedInputType] = useState<string>("");

  const [isCycleDropdownOpen, setIsCycleDropdownOpen] = useState<boolean>(false);

  const [cycle, setCycle] = useState<string>("");
  const handleInputFocus = (isFocus: boolean, type: "cycle") => {
    setIsInputFocused(isFocus);
    setSelectedInputType(type);
    if (type === "cycle") {
      if (!isFocus) {
        setTimeout(() => {
          setIsCycleDropdownOpen(false);
        }, 100);
      }
      setIsCycleDropdownOpen(true);
    }
  };

  // commission ranges items
  const initialCommissionRangesItem: InitialCommissionRangesItem = {
    min: null,
    max: null,
    percentage: null,
    bonus: null,
  };
  const [commissionRangesItems, setCommissionRangesItems] = useState<InitialCommissionRangesItem[]>([]);

  const onAddItemClick = () => {
    setCommissionRangesItems([...commissionRangesItems, initialCommissionRangesItem]);
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const onRemoveItemClick = (index: number) => {
    setIsDeleteModalOpen(true);
    // setCommissionRangesItems(commissionRangesItems.filter((_, i) => i !== index));
  };

  //handle select client
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState<boolean>(false);
  console.log("isClientDropdownOpen:", isClientDropdownOpen);

  return (
    <div className="w-full">
      <TitleButton
        title={t("commission_scheme.create_commission_scheme")}
        onClick={() => navigate(`/cms/${params.organisationCode}/commission-scheme`)}
      />
      <main id="CreateCommissionScheme-main" className="mt-6 w-full">
        <section className="flex w-full items-center gap-3">
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("commission_scheme.scheme_code")} theme={floatingLabelTheme} />
          </div>
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("commission_scheme.scheme_name")} theme={floatingLabelTheme} />
          </div>
          <div className="relative w-full">
            <FloatingLabel
              variant="filled"
              label={t("commission_scheme.cycle")}
              theme={floatingLabelTheme}
              className="cursor-pointer"
              readOnly
              onFocus={() => handleInputFocus(true, "cycle")}
              onBlur={() => handleInputFocus(false, "cycle")}
              value={cycle ? t(`date.${cycle}`) : ""}
            />
            <figure className="absolute right-[12px] top-[25%]">
              <SVGIcon
                icon={isInputFocused && selectedInputType === "cycle" ? TriangleArrowUpIcon : TriangleArrowDownIcon}
                fill="#717071"
                width="24"
                height="24"
              />
            </figure>
            {isCycleDropdownOpen && (
              <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                {feCycleData.map((cycle, index) => {
                  return (
                    <div
                      key={index}
                      className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                      onClick={() => {
                        // handleInputChange(cycle.value, "cycle");
                        setCycle(cycle.placeholder);
                        setIsCycleDropdownOpen(false);
                      }}
                    >
                      {t(`date.${cycle.placeholder}`)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </section>
        <section id="commission-ranges" className="mt-6 flex w-full items-center justify-between">
          <p className="text-dark text-sm font-semibold">{t(`commission_scheme.commission_ranges`)}</p>
          <RoundButton
            text={t("commission_scheme.add_items")}
            onClick={onAddItemClick}
            height="48px"
            py="3"
            px="6"
            gap="12px"
            iconLeft={<SVGIcon icon={AddItemsIcon} fill="#030623" width="24" height="24" />}
            borderRadius="12px"
            bgColor="bg-secondary"
            textColor="text-primary"
            fontWeight="600"
          />
        </section>
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("commission_scheme.delete_item_confirmation")}
        >
          <div className="text-dark w-full">
            {/* <header className="w-full text-base font-semibold">Are you sure you want to delete item 4?</header> */}
            <header className="w-full text-base font-semibold">
              {t("commission_scheme.delete_confirmation_message", { item: `${"item 1"}` })}
            </header>
            <article className="mt-6 w-full">
              <p className="text-base font-semibold">{t("common.item_details")}</p>
              <section className="mt-3 flex flex-col gap-3 text-sm font-medium">
                <div className="flex w-full items-center">
                  <p className="min-w-[120px]">{`${t("common.min")}(≥):`}</p>
                  <p>$200,000</p>
                </div>
                <div className="flex w-full items-center">
                  <p className="min-w-[120px]">{`${t("common.max")}(<):`}</p>
                  <p>$500,000</p>
                </div>
                <div className="flex w-full items-center">
                  <p className="min-w-[120px]">{`${t("common.percentage")}:`}</p>
                  <p>12%</p>
                </div>
                <div className="flex w-full items-center">
                  <p className="min-w-[120px]">{`${t("common.bonus")}:`}</p>
                  <p>$500</p>
                </div>
              </section>
            </article>
            <button className="hover-scale-sm text-dark mt-6 flex h-12 w-full items-center justify-center rounded-xl bg-[#16e6ed] text-sm font-semibold">
              {t("common.delete")}
            </button>
          </div>
        </ConfirmationModal>
        {commissionRangesItems.map((item, index) => {
          return (
            <section id="commission-ranges-items" className="mt-6 flex w-full flex-col gap-6" key={index}>
              <header className="flex w-full items-center justify-between">
                <p className="text-dark text-sm font-semibold">{`${t("common.item")} ${index + 1}`}</p>
                <button
                  id="bin-btn"
                  className="border-secondary hover-scale-sm h-12 items-center justify-center rounded-xl border px-6 py-3"
                  onClick={() => onRemoveItemClick(index)}
                >
                  <SVGIcon icon={BinIcon} fill="#16E6ED" width="24" height="24" />
                </button>
              </header>

              <div className="flex w-full items-center gap-3">
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.min")}(≥)`}
                    theme={floatingLabelTheme}
                    value={`$${"100,000"}`}
                  />
                </div>
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.max")}(<)`}
                    theme={floatingLabelTheme}
                    value={`$${"100,000"}`}
                  />
                </div>
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.percentage")}`}
                    theme={floatingLabelTheme}
                    value={`${"5"}%`}
                  />
                </div>
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.bonus")}`}
                    theme={floatingLabelTheme}
                    value={`$${"100"}`}
                  />
                </div>
              </div>
            </section>
          );
        })}
        <section id="client" className="mt-6 w-full">
          <p className="text-dark text-sm font-semibold">{t("client.client")}</p>
          <figure
            className={`${isClientDropdownOpen && "border-secondary border"} relative mt-6 w-full cursor-pointer rounded-lg bg-white p-3 shadow`}
            onClick={() => setIsClientDropdownOpen(true)}
          >
            <section className="flex w-full items-center">
              <figure className="w-full">
                {/* <p className="text-sm font-medium text-[#717071B3]">{t("client.select_client")}</p> */}
                <div id="selected-client-label-container" className="flex w-full flex-wrap items-center gap-1.5">
                  <figure
                    id="selected-client-label"
                    className="text-dark hover-scale-sm flex items-center justify-between gap-1.5 rounded-[3px] bg-[#0306230D] p-1.5 text-[10px] font-semibold"
                  >
                    <p className="w-full">MedTech Solutions</p>
                    <figure
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <HiOutlineX className="h-3 w-3 text-[#5F6368]" />
                    </figure>
                  </figure>
                  <figure
                    id="selected-client-label"
                    className="text-dark hover-scale-sm flex items-center justify-between gap-1.5 rounded-[3px] bg-[#0306230D] p-1.5 text-[10px] font-semibold"
                  >
                    <p className="w-full">MedTech Solutions</p>
                    <figure
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <HiOutlineX className="h-3 w-3 text-[#5F6368]" />
                    </figure>
                  </figure>
                </div>
              </figure>
              <figure
                onClick={(e) => {
                  e.stopPropagation();
                  setIsClientDropdownOpen(!isClientDropdownOpen);
                }}
              >
                <SVGIcon
                  icon={isClientDropdownOpen ? TriangleArrowUpIcon : TriangleArrowDownIcon}
                  fill="#717071"
                  width="24"
                  height="24"
                />
              </figure>
            </section>
            {isClientDropdownOpen && (
              <section className="animate-fade-in-down absolute left-0 top-[110%] w-full rounded-lg bg-white p-3 shadow">
                <section
                  id="search-client"
                  className="flex w-full items-center gap-3 border-b-[1px] border-[#BDBDBD80] px-3 pb-3 pt-1.5"
                >
                  <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
                  <input
                    placeholder={t("client.search_by_client_name")}
                    className="w-full text-sm font-medium  placeholder:text-[#717071B3] focus:outline-none"
                  />
                  <figure
                    className="hover-scale-sm cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsClientDropdownOpen(false);
                    }}
                  >
                    <SVGIcon icon={CrossIcon} fill="#BDBDBD" width="24" height="24" />
                  </figure>
                </section>
                <section id="client-checkbox-container" className="mt-3 max-h-[140px] overflow-scroll">
                  <figure
                    id="client-checkbox"
                    className="flex w-full items-center gap-3 rounded-lg px-3 py-1.5 hover:bg-[#0306230D]"
                  >
                    <Checkbox id="" theme={checkboxTheme} />
                    <p className="text-dark text-sm font-medium">MedTech Solutions</p>
                  </figure>
                  <figure
                    id="client-checkbox"
                    className="flex w-full items-center gap-3 rounded-lg px-3 py-1.5 hover:bg-[#0306230D]"
                  >
                    <Checkbox id="" theme={checkboxTheme} />
                    <p className="text-dark text-sm font-medium">MedTech Solutions</p>
                  </figure>
                </section>
              </section>
            )}
          </figure>
        </section>
        <section className="mt-[100px] flex w-full justify-end">
          <RoundButton
            text={t("common.save")}
            onClick={() => {}}
            width="max-content"
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
          />
        </section>
      </main>
    </div>
  );
};

export default CreateCommissionScheme;

const feCycleData = [
  { placeholder: "monthly", value: Cycle.MONTHLY },
  { placeholder: "quarterly", value: Cycle.QUARTERLY },
  { placeholder: "half_yearly", value: Cycle.HALF_YEARLY },
  { placeholder: "yearly", value: Cycle.YEARLY },
];
const feEmploymentType = ["FULL_TIME", "PART_TIME", "INTERN"];

const checkboxTheme = {
  root: {
    base: "h-4 w-4 rounded border border-secondary bg-white focus:ring-0 cursor-pointer",
    color: {
      default: "text-[#16E6ED]",
    },
  },
};
