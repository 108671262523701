import { useAppSelector } from "@/redux/store";
import { useTranslation } from "react-i18next";
import { BallTriangle } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { primary } = useAppSelector((state) => state.setting.dynamicColor);

  const handleRedirect = () => {
    navigate(`/cms/heads/dashboard`);
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <main className="mt-10 flex flex-col items-center gap-10">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color={primary}
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <h2 className="font-gensen-regular text-grey">{t("error.page_not_found")}</h2>
        <h4 className="text-primary cursor-pointer hover:underline" onClick={handleRedirect}>
          {t("common.back_to_homepage")}
        </h4>
      </main>
    </div>
  );
};

export default PageNotFound;
