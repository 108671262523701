type Props = {
  size?: number;
  message: string;
};

const ErrorMessage = ({ size, message }: Props) => {
  return (
    <div className="text-red-500">
      <p style={{ fontSize: size ? `${size}px` : "14px" }}>{message}</p>
    </div>
  );
};

export default ErrorMessage;
