import { HiChevronLeft } from "react-icons/hi";

type Props = {
  title: string;
  onClick: () => void;
};
const TitleButton = ({ title, onClick }: Props) => {
  return (
    <div id="TitleButton" className="w-full">
      <div className="hover-scale-sm flex w-fit cursor-pointer items-center gap-[6px]" onClick={onClick}>
        <HiChevronLeft className="text-primary h-9 w-9" />
        <p className="text-lg font-semibold">{title}</p>
      </div>
    </div>
  );
};

export default TitleButton;
