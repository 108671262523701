import { useAppSelector } from "@/redux/store";
import { useLocation } from "react-router-dom";
import SideMenu from "../features/menu/SideMenu";
import FunctionalHeader from "../headers/FunctionalHeader";

type Props = {
  children?: React.ReactNode;
};

const CmsLayout = ({ children }: Props) => {
  const location = useLocation();

  if (location.pathname.includes("login")) {
    return (
      <div id="CmsLayout main" className="w-full">
        <div className="flex w-full">{children}</div>
      </div>
    );
  }

  return (
    <div id="CmsLayout main" className="font-dm-sans w-full">
      <div className="flex w-full">
        <SideMenu />
        <div className="h-screen w-full overflow-scroll p-9">
          <FunctionalHeader />
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CmsLayout;
