import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "@/locale/translation/en.json";
import zh_hk from "@/locale/translation/zh_hk.json";
import zh_cn from "@/locale/translation/zh_cn.json";

const resources = {
  en: {
    translation: en,
  },
  zh_HK: {
    translation: zh_hk,
  },
  zh_CN: {
    translation: zh_cn,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: (typeof window !== "undefined" && localStorage.getItem("lang")) || "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
