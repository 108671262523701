import TitleButton from "@/components/buttons/TitleButton";
import { useTranslation } from "react-i18next";
import RoundButton from "@/components/buttons/RoundButton";
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/svg/crossIcon.svg";
import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, FloatingLabel } from "flowbite-react";
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { useState } from "react";
import { HiOutlineX } from "react-icons/hi";

const CreateClient = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  //handle select commission scheme
  const [isCommissionSchemeDropdownOpen, setIsCommissionSchemeDropdownOpen] = useState<boolean>(false);

  return (
    <div className="w-full">
      <TitleButton
        title={t("client.create_client")}
        onClick={() => navigate(`/cms/${params.organisationCode}/client`)}
      />
      <header className="flex w-full items-center justify-between text-sm font-semibold">
        <p className="text-dark">{t("client.client_information")}</p>
        <button>
          <RoundButton
            text={t("common.save")}
            onClick={() => {}}
            width="max-content"
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
          />
        </button>
      </header>
      <main id="CreateClient-main" className="mt-6 w-full">
        <section className="flex w-full items-center gap-3">
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("client.client_code")} theme={floatingLabelTheme} />
          </div>
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("client.client_name")} theme={floatingLabelTheme} />
          </div>
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("client.contact_person")} theme={floatingLabelTheme} />
          </div>
        </section>
        <section className="flex w-full items-center gap-3">
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("client.contact_number")} theme={floatingLabelTheme} />
          </div>
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("client.email_address")} theme={floatingLabelTheme} />
          </div>
        </section>
        <section className="flex w-full items-center gap-3">
          <div className="w-full">
            <FloatingLabel variant="filled" label={t("client.address")} theme={floatingLabelTheme} />
          </div>
        </section>

        <section id="commission_scheme" className="mt-6 w-full">
          <p className="text-dark text-sm font-semibold">{t("client.commission_scheme")}</p>
          <figure
            className={`${isCommissionSchemeDropdownOpen && "border-secondary border"} relative mt-6 w-full cursor-pointer rounded-lg bg-white p-3 shadow`}
            onClick={() => setIsCommissionSchemeDropdownOpen(true)}
          >
            <section className="flex w-full items-center">
              <figure className="w-full">
                {/* <p className="text-sm font-medium text-[#717071B3]">{t("client.select_commission_scheme")}</p> */}
                <div
                  id="selected-commission_scheme-label-container"
                  className="flex w-full flex-wrap items-center gap-1.5"
                >
                  <figure
                    id="selected-commission_scheme-label"
                    className="text-dark hover-scale-sm flex items-center justify-between gap-1.5 rounded-[3px] bg-[#0306230D] p-1.5 text-[10px] font-semibold"
                  >
                    <p className="w-full">Achievement Reward</p>
                    <figure
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <HiOutlineX className="h-3 w-3 text-[#5F6368]" />
                    </figure>
                  </figure>
                  <figure
                    id="selected-commission_scheme-label"
                    className="text-dark hover-scale-sm flex items-center justify-between gap-1.5 rounded-[3px] bg-[#0306230D] p-1.5 text-[10px] font-semibold"
                  >
                    <p className="w-full">Team Performance Bonus</p>
                    <figure
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <HiOutlineX className="h-3 w-3 text-[#5F6368]" />
                    </figure>
                  </figure>
                </div>
              </figure>
              <figure
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCommissionSchemeDropdownOpen(!isCommissionSchemeDropdownOpen);
                }}
              >
                <SVGIcon
                  icon={isCommissionSchemeDropdownOpen ? TriangleArrowUpIcon : TriangleArrowDownIcon}
                  fill="#717071"
                  width="24"
                  height="24"
                />
              </figure>
            </section>
            {isCommissionSchemeDropdownOpen && (
              <section className="animate-fade-in-down absolute left-0 top-[110%] w-full rounded-lg bg-white p-3 shadow">
                <section
                  id="search-commission_scheme"
                  className="flex w-full items-center gap-3 border-b-[1px] border-[#BDBDBD80] px-3 pb-3 pt-1.5"
                >
                  <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
                  <input
                    placeholder={t("client.search_by_commission_scheme")}
                    className="w-full text-sm font-medium placeholder:text-[#717071B3] focus:outline-none"
                  />
                  <figure
                    className="hover-scale-sm cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCommissionSchemeDropdownOpen(false);
                    }}
                  >
                    <SVGIcon icon={CrossIcon} fill="#BDBDBD" width="24" height="24" />
                  </figure>
                </section>
                <section id="commission_scheme-checkbox-container" className="mt-3 max-h-[140px] overflow-scroll">
                  <figure
                    id="commission_scheme-checkbox"
                    className="flex w-full items-center gap-3 rounded-lg px-3 py-1.5 hover:bg-[#0306230D]"
                  >
                    <Checkbox id="" theme={checkboxTheme} />
                    <p className="text-dark text-sm font-medium">Achievement Reward</p>
                  </figure>
                  <figure
                    id="commission_scheme-checkbox"
                    className="flex w-full items-center gap-3 rounded-lg px-3 py-1.5 hover:bg-[#0306230D]"
                  >
                    <Checkbox id="" theme={checkboxTheme} />
                    <p className="text-dark text-sm font-medium">Team Performance Bonus</p>
                  </figure>
                </section>
              </section>
            )}
          </figure>
        </section>
      </main>
    </div>
  );
};

export default CreateClient;

const checkboxTheme = {
  root: {
    base: "h-4 w-4 rounded border border-secondary bg-white focus:ring-0 cursor-pointer",
    color: {
      default: "text-[#16E6ED]",
    },
  },
};
