import { Outlet } from "react-router-dom";
import CmsLayout from "./CmsLayout";

const CmsRouteLayout = () => {
  return (
    <CmsLayout>
      <Outlet />
    </CmsLayout>
  );
};

export default CmsRouteLayout;
