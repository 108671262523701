import { useAppSelector } from "@/redux/store";
import { RotatingLines } from "react-loader-spinner";

// Remember add relative on parent's div
const LoadingSpinner = () => {
  const { primary, secondary } = useAppSelector((state) => state.setting.dynamicColor);

  return (
    <div className="bg-white-600 absolute z-50 flex h-full w-full items-center justify-center backdrop-blur-sm">
      <RotatingLines
        visible={true}
        width="36"
        strokeColor={secondary}
        strokeWidth="5"
        animationDuration="0.5"
        ariaLabel="rotating-lines-loading"
      />
    </div>
  );
};

export default LoadingSpinner;
