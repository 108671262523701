import { useEffect } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  message: string;
};

const NotificationToast = ({ message }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const notifySuccess = () => {
    toast.success(`${message}`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      closeButton: false,
      bodyClassName: "text-dark font-dm-sans",
    });
  };

  const notifyError = () => {
    toast.error(`${message}`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      closeButton: false,
      bodyClassName: "text-dark font-dm-sans",
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "success") {
      notifySuccess();
    }
    if (status === "error") {
      notifyError();
    }
    const timer = setTimeout(() => {
      navigate(location.pathname, { replace: true });
    }, 3000);

    return () => clearTimeout(timer);
  }, [location.search, navigate, location.pathname]);

  return (
    <>
      <ToastContainer style={{ width: "fit-content" }} bodyStyle={{ padding: "18px" }} />
    </>
  );
};

export default NotificationToast;
