import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import SVGIcon from "../SVGIcons/SVGIcon";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { convertHyphensToUnderscores } from "@/utils/functions";

type Props = {
  inputChange: (value: string) => void;
};

const SearchInput = ({ inputChange }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPage = convertHyphensToUnderscores(location.pathname.split("/")[3]);

  const handleInputChange = (value: string) => {
    inputChange(value);
  };

  const renderMessageByCurrentPage = (): string => {
    const messageMap: Record<string, string> = {
      employee_management: "employee_management_search_message",
      client: "client_search_message",
      commission_scheme: "commission_scheme_search_message",
      leave: "leave_search_message",
      holiday: "holiday_search_message",
      payroll: "payroll_search_message",
    };

    return messageMap[currentPage] || "default_search_message";
  };

  return (
    <div id="search-input" className="flex w-full flex-1 items-center gap-3 rounded-xl border border-[#030623] p-1.5">
      <figure id="icon" className="ml-[12px] flex h-6 w-6 items-center justify-center">
        <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
      </figure>
      <section id="input" className="w-full">
        <input
          type="text"
          placeholder={t(`search.${renderMessageByCurrentPage()}`)}
          className="w-full border-none px-0 text-sm font-medium focus:ring-0"
          onChange={(e) => handleInputChange(e.target.value)}
        />
      </section>
      {/* <button
        id="search-button"
        className="bg-primary hover-scale-sm flex h-9 w-[90px] items-center justify-center gap-[6px] rounded-[10px] px-3 py-[6px] text-xs font-medium text-white"
      >
        <figure className="mb-1">
          <SVGIcon icon={SearchMagnifierIcon} fill="#ffffff" width="20" height="20" />
        </figure>
        <p>{t("common.search")}</p>
      </button> */}
    </div>
  );
};

export default SearchInput;
