import TitleButton from "@/components/buttons/TitleButton";
import { useTranslation } from "react-i18next";
import RoundButton from "@/components/buttons/RoundButton";
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import { ReactComponent as DatePickerIcon } from "@/assets/icons/svg/datePickerIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { useNavigate, useParams } from "react-router-dom";
import { FloatingLabel } from "flowbite-react";
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { useEffect, useState } from "react";
import { HolidayType } from "@/types/enums";
import CustomDatePicker from "@/components/pickers/CustomDatePicker";

const CreateHolidayRecord = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // normal input change
  const handleInputChange = (value: any, name: string) => {
    // setCreateEmployeeData((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  //handle select employee name
  const [isEmployeeNameDropdownOpen, setIsEmployeeNameDropdownOpen] = useState<boolean>(false);

  //handle startDate and endDate
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [selectedInputType, setSelectedInputType] = useState<string>("");

  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);

  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);

  //handle select holiday types
  const [isLeaveTypeDropdownOpen, setIsLeaveTypeDropdownOpen] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<string>("");

  //handle select approval information
  const [isApprovalStatusDropdownOpen, setIsApprovalStatusDropdownOpen] = useState<boolean>(false);
  const [approvalStatus, setApprovalStatus] = useState<string>("");

  const handleInputFocus = (isFocus: boolean, type: "start_date" | "end_date" | "leave_type" | "approval_status") => {
    setIsInputFocused(isFocus);
    setSelectedInputType(type);

    if (type === "start_date") {
      setIsStartDatePickerOpen(true);
    }
    if (type === "end_date") {
      setIsEndDatePickerOpen(true);
    }
    if (type === "leave_type") {
      if (!isFocus) {
        setTimeout(() => {
          setIsLeaveTypeDropdownOpen(false);
        }, 100);
      }
      setIsLeaveTypeDropdownOpen(true);
    }
    if (type === "approval_status") {
      if (!isFocus) {
        setTimeout(() => {
          setIsApprovalStatusDropdownOpen(false);
        }, 100);
      }
      setIsApprovalStatusDropdownOpen(true);
    }
  };
  // control date-pickers onClose when state changed
  useEffect(() => {
    setIsStartDatePickerOpen(false);
  }, [startDate]);
  useEffect(() => {
    setIsEndDatePickerOpen(false);
  }, [endDate]);

  return (
    <div className="w-full">
      <TitleButton
        title={t("holiday.create_holiday_record")}
        onClick={() => navigate(`/cms/${params.organisationCode}/holiday`)}
      />
      <header className="mt-6 flex w-full items-center justify-between text-sm font-semibold">
        <p className="text-dark">{t("holiday.holiday_information")}</p>
        <button>
          <RoundButton
            text={t("common.save")}
            onClick={() => {}}
            width="max-content"
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
          />
        </button>
      </header>
      <main id="CreateHolidayRecord-main" className="mt-6 w-full">
        <article id="holiday-details-container">
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel variant="filled" label={t("holiday.reason")} theme={floatingLabelTheme} />
            </div>
            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("holiday.search_leave_type")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => handleInputFocus(true, "leave_type")}
                onBlur={() => handleInputFocus(false, "leave_type")}
                value={leaveType ? t(`holiday.${leaveType.toLowerCase()}`) : ""}
              />
              <figure className="absolute right-[12px] top-[25%]">
                <SVGIcon
                  icon={
                    isInputFocused && selectedInputType === "leave_type" ? TriangleArrowUpIcon : TriangleArrowDownIcon
                  }
                  fill="#717071"
                  width="24"
                  height="24"
                />
              </figure>
              {isLeaveTypeDropdownOpen && (
                <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                  {feHolidayTypeData.map((type, index) => {
                    return (
                      <div
                        key={index}
                        className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                        onClick={() => {
                          handleInputChange(type, "leave_type");
                          setLeaveType(type);
                          setIsLeaveTypeDropdownOpen(false);
                        }}
                      >
                        {t(`holiday.${type.toLowerCase()}`)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </section>

          <section className="mt-6 flex w-full items-center gap-3">
            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("holiday.start_date")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => {
                  handleInputFocus(true, "start_date");
                }}
                onBlur={() => handleInputFocus(false, "start_date")}
                // value={formatDateFromHyphenToSlash(position.startDate)}
              />
              <figure
                className="absolute right-[12px] top-[25%] cursor-pointer hover-scale-sm"
                onClick={() => setIsStartDatePickerOpen(!isStartDatePickerOpen)}
              >
                <SVGIcon
                  icon={DatePickerIcon}
                  fill={isInputFocused && selectedInputType === "start_date" ? "#16E6ED" : "#717071B3"}
                  width="24"
                  height="24"
                />
              </figure>
              {isStartDatePickerOpen && (
                <figure className="absolute right-0 top-12 z-50">
                  <CustomDatePicker
                    onSelectedDate={(value) => {
                      setStartDate(value);
                      // handlePositionValueChange(index, "startDate", formatDateByHyphen(value));
                    }}
                    onClose={() => setIsStartDatePickerOpen(false)}
                  />
                </figure>
              )}
            </div>
            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("holiday.end_date")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => {
                  handleInputFocus(true, "end_date");
                }}
                onBlur={() => handleInputFocus(false, "end_date")}
                // value={formatDateFromHyphenToSlash(position.endDate)}
              />
              <figure
                className="absolute right-[12px] top-[25%] cursor-pointer hover-scale-sm"
                onClick={() => setIsEndDatePickerOpen(!isEndDatePickerOpen)}
              >
                <SVGIcon
                  icon={DatePickerIcon}
                  fill={isInputFocused && selectedInputType === "end_date" ? "#16E6ED" : "#717071B3"}
                  width="24"
                  height="24"
                />
              </figure>
              {isEndDatePickerOpen && (
                <figure className="absolute right-0 top-12 z-50">
                  <CustomDatePicker
                    onSelectedDate={(value) => {
                      setEndDate(value);
                      // handlePositionValueChange(index, "endDate", formatDateByHyphen(value));
                    }}
                    onClose={() => setIsEndDatePickerOpen(false)}
                  />
                </figure>
              )}
            </div>
          </section>
        </article>
      </main>
    </div>
  );
};

export default CreateHolidayRecord;

const feHolidayTypeData = [HolidayType.STATUTORY, HolidayType.COMPANY, HolidayType.OTHER];
