import { ReactComponent as SettingIcon } from "@/assets/icons/svg/header/settingIcon.svg";
import { ReactComponent as NotificationIcon } from "@/assets/icons/svg/header/notificationIcon.svg";
import SVGIcon from "../SVGIcons/SVGIcon";
import { Avatar } from "flowbite-react";
import defaultImageSquare from "@/assets/defaults/defaultImageSquare.jpeg";
import { useLocation } from "react-router-dom";
import { convertHyphensToUnderscores } from "@/utils/functions";
import { useTranslation } from "react-i18next";
import userDefaultAvatar from "@/assets/defaults/userDefaultAvatar.png";

const FunctionalHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname.split("/")[3];

  return (
    <div id="FunctionalHeader" className="mb-9 flex w-full items-center justify-between">
      <section id="header-title" className="text-dark text-2xl font-semibold">
        {t(`side_menu.${convertHyphensToUnderscores(pathname)}`)}
      </section>
      <section id="header-functions" className="flex items-center gap-6">
        <figure className="hover-scale-sm cursor-pointer">
          <SVGIcon icon={SettingIcon} fill={""} width="36" height="36" />
        </figure>
        <figure className="hover-scale-sm cursor-pointer">
          <SVGIcon icon={NotificationIcon} fill={""} width="36" height="36" />
        </figure>
        <Avatar
          rounded
          img={userDefaultAvatar}
          className="hover-scale-sm h-9 w-9 cursor-pointer overflow-hidden rounded-full"
        />
      </section>
    </div>
  );
};

export default FunctionalHeader;
