import { useState } from "react";
import { Dropdown } from "flowbite-react";
import { ReactComponent as DropdownIcon } from "@/assets/icons/svg/dropdownIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { useTranslation } from "react-i18next";
import { languagesData } from "@/redux/setting/settingSlice";
import { HiOutlineGlobeAlt } from "react-icons/hi";

type Props = {
  isCms?: boolean;
};

const LocaleDropdown = ({ isCms }: Props) => {
  const { i18n } = useTranslation();

  const handleLangName = (lang: string) => {
    const languageNames: { [key: string]: string } = {
      en: "Eng",
      zh_HK: "繁中",
      zh_CN: "简中",
    };
    return languageNames[lang] || "繁中";
  };
  const [langName, setLangName] = useState<string>(handleLangName(i18n.language));

  const handleChangeLanguage = (name: string, lang: string) => {
    setLangName(name);
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const triggerButton = () => {
    return (
      <div className="hover-scale-sm flex h-[40px] w-[87px] cursor-pointer items-center justify-center rounded-[30px] border border-[var(--primary-color)]">
        <p className="text-primary font-gensen-regular text-sm">{langName}</p>
        <SVGIcon icon={DropdownIcon} fill="var(--primary-color)" width="24" height="24" />
      </div>
    );
  };

  const cmsTriggerButton = () => {
    return (
      <div className="flex h-[40px] w-[87px] items-center justify-center">
        <HiOutlineGlobeAlt color="#fff" size={24} className="hover-scale-sm cursor-pointer" />
      </div>
    );
  };
  return (
    <div>
      <Dropdown
        label="Dropdown button"
        dismissOnClick={true}
        renderTrigger={isCms ? () => cmsTriggerButton() : () => triggerButton()}
        trigger="hover"
        className="border-primary z-10 rounded-md bg-white focus:outline-none"
      >
        {languagesData.map((lang) => {
          return (
            <Dropdown.Item
              className={
                "hover:bg-hover-color-main text-primary font-gensen-regular flex justify-center py-1 hover:text-white"
              }
              style={{ width: isCms ? "87px" : "" }}
              key={lang.id}
              onClick={() => handleChangeLanguage(lang.name, lang.lang)}
            >
              {lang.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    </div>
  );
};

export default LocaleDropdown;
