import { setDynamicColor } from "@/redux/setting/settingSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import React, { useEffect, useState } from "react";

const ThemeProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const { primary, secondary } = useAppSelector((state) => state.setting.dynamicColor);

  useEffect(() => {
    dispatch(setDynamicColor({ primary: "#030623", secondary: "#16E6ED" }));
  }, []);

  const styles = {
    "--primary-color": "#030623",
    "--secondary-color": "#16E6ED",
  };

  return <div style={styles}>{children}</div>;
};

export default ThemeProvider;
