import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  toastDetails: { isToggle: false, message: "", status: "warning" },
  isFetchCompleted: false,
};

const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    setToastDetails(state, { payload }: PayloadAction<any>) {
      state.toastDetails = payload;
    },
    setIsFetchCompleted(state, { payload }: PayloadAction<any>) {
      state.isFetchCompleted = payload;
    },
  },
});

export const { setToastDetails, setIsFetchCompleted } = utilSlice.actions;

export default utilSlice.reducer;
