import { api } from "@/api/apiClient";
import { CreateClient } from "@/types/client";
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";

export const getClient = (id: number) => {
  return api.get(`/client/${id}`);
};

export const getClientList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string
) => {
  return api.get(`/client`, {
    params: {
      page,
      limit,
      sortColumn,
      sortOrder,
      searchValue,
    },
  });
};

export const createClient = (data: CreateClient) => {
  return api.post(`/client`, data);
};

export const updateClient = (id: number, data: CreateClient) => {
  return api.patch(`/client/${id}`, data);
};

export const deleteClient = (id: number) => {
  return api.delete(`/client/${id}`);
};
