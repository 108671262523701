import TitleButton from "@/components/buttons/TitleButton";
import { useTranslation } from "react-i18next";
import RoundButton from "@/components/buttons/RoundButton";
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/svg/crossIcon.svg";
import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import { ReactComponent as DatePickerIcon } from "@/assets/icons/svg/datePickerIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, FloatingLabel } from "flowbite-react";
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { LeaveStatus, LeaveType } from "@/types/enums";
import CustomDatePicker from "@/components/pickers/CustomDatePicker";

const CreateLeaveRecord = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // normal input change
  const handleInputChange = (value: any, name: string) => {
    // setCreateEmployeeData((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  //handle select employee name
  const [isEmployeeNameDropdownOpen, setIsEmployeeNameDropdownOpen] = useState<boolean>(false);

  //handle startDate and endDate
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [selectedInputType, setSelectedInputType] = useState<string>("");

  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);

  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);

  //handle select leave types
  const [isLeaveTypeDropdownOpen, setIsLeaveTypeDropdownOpen] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<string>("");

  //handle select approval information
  const [isApprovalStatusDropdownOpen, setIsApprovalStatusDropdownOpen] = useState<boolean>(false);
  const [approvalStatus, setApprovalStatus] = useState<string>("");

  const handleInputFocus = (isFocus: boolean, type: "start_date" | "end_date" | "leave_type" | "approval_status") => {
    setIsInputFocused(isFocus);
    setSelectedInputType(type);

    if (type === "start_date") {
      setIsStartDatePickerOpen(true);
    }
    if (type === "end_date") {
      setIsEndDatePickerOpen(true);
    }
    if (type === "leave_type") {
      if (!isFocus) {
        setTimeout(() => {
          setIsLeaveTypeDropdownOpen(false);
        }, 100);
      }
      setIsLeaveTypeDropdownOpen(true);
    }
    if (type === "approval_status") {
      if (!isFocus) {
        setTimeout(() => {
          setIsApprovalStatusDropdownOpen(false);
        }, 100);
      }
      setIsApprovalStatusDropdownOpen(true);
    }
  };
  // control date-pickers onClose when state changed
  useEffect(() => {
    setIsStartDatePickerOpen(false);
  }, [startDate]);
  useEffect(() => {
    setIsEndDatePickerOpen(false);
  }, [endDate]);

  return (
    <div className="w-full">
      <TitleButton
        title={t("leave.create_leave_record")}
        onClick={() => navigate(`/cms/${params.organisationCode}/leave`)}
      />
      <header className="mt-6 flex w-full items-center justify-between text-sm font-semibold">
        <p className="text-dark">{t("leave.employee_information")}</p>
        <button>
          <RoundButton
            text={t("common.save")}
            onClick={() => {}}
            width="max-content"
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
          />
        </button>
      </header>
      <main id="CreateLeaveRecord-main" className="mt-6 w-full">
        <section id="employee_information-dropdown" className="mt-6 w-full">
          <figure
            className={`${isEmployeeNameDropdownOpen && "border-secondary border"} relative mt-6 w-full cursor-pointer rounded-lg bg-white p-3 shadow`}
            onClick={() => setIsEmployeeNameDropdownOpen(true)}
          >
            <section className="flex w-full items-center">
              <figure className="w-full">
                <p className="text-sm font-medium text-[#717071B3]">{t("leave.employee_name")}</p>
                {/* <p className="text-sm font-medium text-dark">{}</p> */}
              </figure>
              <figure
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEmployeeNameDropdownOpen(!isEmployeeNameDropdownOpen);
                }}
              >
                <SVGIcon
                  icon={isEmployeeNameDropdownOpen ? TriangleArrowUpIcon : TriangleArrowDownIcon}
                  fill="#717071"
                  width="24"
                  height="24"
                />
              </figure>
            </section>

            {isEmployeeNameDropdownOpen && (
              <section className="animate-fade-in-down absolute left-0 top-[110%] z-20 w-full rounded-lg bg-white p-3 shadow">
                <section
                  id="search-employee_information"
                  className="flex w-full items-center gap-3 border-b-[1px] border-[#BDBDBD80] px-3 pb-3 pt-1.5"
                >
                  <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
                  <input
                    placeholder={t("leave.search_by_employee_name")}
                    className="w-full text-sm font-medium placeholder:text-[#717071B3] focus:outline-none"
                  />
                  <figure
                    className="hover-scale-sm cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEmployeeNameDropdownOpen(false);
                    }}
                  >
                    <SVGIcon icon={CrossIcon} fill="#BDBDBD" width="24" height="24" />
                  </figure>
                </section>
                <section id="employee-name-container" className="mt-3 max-h-[140px] overflow-scroll">
                  <figure
                    id="employee-name"
                    className="flex w-full items-center gap-3 rounded-lg px-3 py-1.5 hover:bg-[#0306230D]"
                  >
                    <p className="text-dark text-sm font-medium">Kenneth Lam</p>
                  </figure>
                  <figure
                    id="employee-name"
                    className="flex w-full items-center gap-3 rounded-lg px-3 py-1.5 hover:bg-[#0306230D]"
                  >
                    <p className="text-dark text-sm font-medium">Jackal Cheung</p>
                  </figure>
                </section>
              </section>
            )}
          </figure>
        </section>

        <article id="leave-details-container">
          <p className="text-dark mt-6 text-sm font-semibold">{t("leave.leave_details")}</p>

          <div className="relative mt-6 w-full">
            <FloatingLabel
              variant="filled"
              label={t("leave.search_leave_type")}
              theme={floatingLabelTheme}
              className="cursor-pointer"
              readOnly
              onFocus={() => handleInputFocus(true, "leave_type")}
              onBlur={() => handleInputFocus(false, "leave_type")}
              value={leaveType ? t(`leave.${leaveType.toLowerCase()}`) : ""}
            />
            <figure className="absolute right-[12px] top-[25%]">
              <SVGIcon
                icon={
                  isInputFocused && selectedInputType === "leave_type" ? TriangleArrowUpIcon : TriangleArrowDownIcon
                }
                fill="#717071"
                width="24"
                height="24"
              />
            </figure>
            {isLeaveTypeDropdownOpen && (
              <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                {feLeaveTypeData.map((type, index) => {
                  return (
                    <div
                      key={index}
                      className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                      onClick={() => {
                        handleInputChange(type, "leave_type");
                        setLeaveType(type);
                        setIsLeaveTypeDropdownOpen(false);
                      }}
                    >
                      {t(`leave.${type.toLowerCase()}`)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <section className="mt-6 flex w-full items-center gap-3">
            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("leave.start_date")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => {
                  handleInputFocus(true, "start_date");
                }}
                onBlur={() => handleInputFocus(false, "start_date")}
                // value={formatDateFromHyphenToSlash(position.startDate)}
              />
              <figure
                className="absolute right-[12px] top-[25%] cursor-pointer hover-scale-sm"
                onClick={() => setIsStartDatePickerOpen(!isStartDatePickerOpen)}
              >
                <SVGIcon
                  icon={DatePickerIcon}
                  fill={isInputFocused && selectedInputType === "start_date" ? "#16E6ED" : "#717071B3"}
                  width="24"
                  height="24"
                />
              </figure>
              {isStartDatePickerOpen && (
                <figure className="absolute right-0 top-12 z-50">
                  <CustomDatePicker
                    onSelectedDate={(value) => {
                      setStartDate(value);
                      // handlePositionValueChange(index, "startDate", formatDateByHyphen(value));
                    }}
                    onClose={() => setIsStartDatePickerOpen(false)}
                  />
                </figure>
              )}
            </div>
            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("leave.end_date")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => {
                  handleInputFocus(true, "end_date");
                }}
                onBlur={() => handleInputFocus(false, "end_date")}
                // value={formatDateFromHyphenToSlash(position.endDate)}
              />
              <figure
                className="absolute right-[12px] top-[25%] cursor-pointer hover-scale-sm"
                onClick={() => setIsEndDatePickerOpen(!isEndDatePickerOpen)}
              >
                <SVGIcon
                  icon={DatePickerIcon}
                  fill={isInputFocused && selectedInputType === "end_date" ? "#16E6ED" : "#717071B3"}
                  width="24"
                  height="24"
                />
              </figure>
              {isEndDatePickerOpen && (
                <figure className="absolute right-0 top-12 z-50">
                  <CustomDatePicker
                    onSelectedDate={(value) => {
                      setEndDate(value);
                      // handlePositionValueChange(index, "endDate", formatDateByHyphen(value));
                    }}
                    onClose={() => setIsEndDatePickerOpen(false)}
                  />
                </figure>
              )}
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("leave.number_of_days")}
                theme={floatingLabelTheme}
                // onChange={(e) => handleInputChange(e.target.value, "email")}
                // value={createEmployeeData.email}
              />
            </div>
          </section>

          <article id="approval_information-container">
            <p className="text-dark mt-6 text-sm font-semibold">{t("leave.approval_information")}</p>
            <section id="approval_information-dropdown" className="mt-6 w-full">
              <div className="relative w-full">
                <FloatingLabel
                  variant="filled"
                  label={t("leave.approval_status")}
                  theme={floatingLabelTheme}
                  className="cursor-pointer"
                  readOnly
                  onFocus={() => handleInputFocus(true, "approval_status")}
                  onBlur={() => handleInputFocus(false, "approval_status")}
                  value={approvalStatus ? t(`leave.${approvalStatus.toLowerCase()}`) : ""}
                />
                <figure className="absolute right-[12px] top-[25%]">
                  <SVGIcon
                    icon={
                      isInputFocused && selectedInputType === "approval_status"
                        ? TriangleArrowUpIcon
                        : TriangleArrowDownIcon
                    }
                    fill="#717071"
                    width="24"
                    height="24"
                  />
                </figure>
                {isApprovalStatusDropdownOpen && (
                  <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                    {feApprovalStatus.map((status, index) => {
                      return (
                        <div
                          key={index}
                          className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                          onClick={() => {
                            handleInputChange(status, "approval_status");
                            setApprovalStatus(status);
                            setIsApprovalStatusDropdownOpen(false);
                          }}
                        >
                          {t(`leave.${status.toLowerCase()}`)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </section>
          </article>

          <article id="reason_for_leave-container">
            <p className="text-dark mt-6 text-sm font-semibold">{t("leave.reason_for_leave")}</p>
            <section className="mt-6 w-full">
              <div className="w-full">
                <FloatingLabel variant="filled" label={t("leave.reason")} theme={floatingLabelTheme} />
              </div>
            </section>
          </article>
        </article>
      </main>
    </div>
  );
};

export default CreateLeaveRecord;

const feLeaveTypeData = [
  LeaveType.ANNUAL_LEAVE,
  LeaveType.NO_PAY_LEAVE,
  LeaveType.SICK_LEAVE,
  LeaveType.NO_PAY_SICK_LEAVE,
  LeaveType.MATERNITY_LEAVE,
  LeaveType.INJURY_LEAVE,
  LeaveType.BIRTHDAY_LEAVE,
];

const feApprovalStatus = [LeaveStatus.PENDING, LeaveStatus.APPROVED, LeaveStatus.REJECTED, LeaveStatus.CANCELLED];
