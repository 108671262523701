import { api } from "@/api/apiClient";
import { CreateEmployee, SortColumn, UpdateEmployee } from "@/types/employee";
import { SortOrder } from "@/types/util";

export const getEmployee = (id: number) => {
  return api.get(`/employee/${id}`);
};

export const getEmployeeList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string
) => {
  return api.get(`/employee`, {
    params: {
      page,
      limit,
      sortColumn,
      sortOrder,
      searchValue,
    },
  });
};

export const createEmployee = (data: CreateEmployee) => {
  return api.post(`/employee`, data);
};

export const updateEmployee = (id: number, data: UpdateEmployee) => {
  return api.patch(`/employee/${id}`, data);
};

export const deleteEmployee = (id: number) => {
  return api.delete(`/employee/${id}`);
};
