export const linkedSchemeTooltip = {
  target: "w-fit",
  animation: "transition-opacity",
  arrow: {
    base: "absolute z-10 h-2 w-2 rotate-45",
    style: {
      dark: "bg-gray-900 ",
      light: "bg-white",
      auto: "bg-white ",
    },
    placement: "-4px",
  },
  base: "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-sm",
  hidden: "invisible opacity-0",
  style: {
    dark: "bg-gray-900 text-white ",
    light: "bg-white",
    auto: "border border-gray-200 bg-white text-gray-900",
  },
  content: "relative z-20",
};
