import { Route, Routes } from "react-router-dom";
import "./App.css";
import PageNotFound from "./pages/PageNotFound";
import CmsRoutes from "./routes/cms/CmsRoutes";

function App() {
  return (
    <>
      <Routes>
        <Route path="/cms/*" element={<CmsRoutes />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
