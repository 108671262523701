import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { ReactComponent as HeadsLogoFullDark } from "@/assets/logos/headsLogoFullDark.svg";
import { ReactComponent as HeadsLogoHalfDark } from "@/assets/logos/headsLogoHalfDark.svg";
import { ReactComponent as DashboardIcon } from "@/assets/icons/svg/sideMenu/dashboardIcon.svg";
import { ReactComponent as EmployeeManagementIcon } from "@/assets/icons/svg/sideMenu/employeeManagementIcon.svg";
import { ReactComponent as ClientIcon } from "@/assets/icons/svg/sideMenu/clientIcon.svg";
import { ReactComponent as SchemeIcon } from "@/assets/icons/svg/sideMenu/schemeIcon.svg";
import { ReactComponent as LeaveRecordIcon } from "@/assets/icons/svg/sideMenu/leaveRecordIcon.svg";
import { ReactComponent as HolidayRecordIcon } from "@/assets/icons/svg/sideMenu/holidayRecordIcon.svg";
import { ReactComponent as PayrollIcon } from "@/assets/icons/svg/sideMenu/payrollIcon.svg";
import { ReactComponent as LogoutIcon } from "@/assets/icons/svg/sideMenu/logoutIcon.svg";
import { useTranslation } from "react-i18next";
import LocaleDropdown from "../dropdown/localeDropdown/LocaleDropdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertHyphensToUnderscores, getOrganisationCode } from "@/utils/functions";

const SideMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { dynamicColor } = useAppSelector((state) => state.setting);

  const [isOpen, setIsOpen] = useState(true);
  const [permissionItems, setPermissionItems] = useState<string[]>([]);

  //initiate pathname conversion e.g.(employee-management > EMPLOYEE_MANAGEMENT)
  const location = useLocation();
  const pathname = convertHyphensToUnderscores(location.pathname.split("/")[3]).toUpperCase();

  //selected menuitem and render by pathname
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(pathname);
  useEffect(() => {
    setSelectedMenuItem(pathname);
  }, [pathname]);

  //text show timing for styles
  const [isTextShow, setIsTextShow] = useState(true);
  const handleTextShowing = () => {
    if (isOpen) {
      setTimeout(() => {
        setIsTextShow(true);
      }, 160);
    }
    setIsTextShow(false);
  };
  useEffect(() => {
    handleTextShowing();
  }, [isOpen]);

  //icon mapping
  const handleIconMapping = (name: string): React.FC => {
    const iconMapping: Record<string, React.FC> = {
      DASHBOARD: DashboardIcon,
      EMPLOYEE_MANAGEMENT: EmployeeManagementIcon,
      CLIENT: ClientIcon,
      COMMISSION_SCHEME: SchemeIcon,
      LEAVE: LeaveRecordIcon,
      HOLIDAY: HolidayRecordIcon,
      PAYROLL: PayrollIcon,
    };
    return iconMapping[name];
  };

  //item onClick > selected item and navigation
  const onItemClick = (item: string) => {
    setSelectedMenuItem(item);
    const pathMapping: Record<string, string> = {
      DASHBOARD: "dashboard",
      EMPLOYEE_MANAGEMENT: "employee-management",
      CLIENT: "client",
      COMMISSION_SCHEME: "commission-scheme",
      LEAVE: "leave",
      HOLIDAY: "holiday",
      PAYROLL: "payroll",
    };
    navigate(`/cms/${getOrganisationCode()}/${pathMapping[item]}`);
  };

  //handle logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate(`/cms/${params.organisationCode}/login`);
  };

  return (
    <div
      id="side-menu"
      className={`bg-primary h-[1194px] ${isOpen ? "w-[318px]" : "w-[148px]"} relative h-screen rounded-br-xl rounded-tr-xl px-6 py-7 transition-all duration-300`}
    >
      <button
        className="bg-secondary hover-scale absolute right-0 top-[26px] flex h-12 w-6 items-center justify-center rounded-bl-[10px] rounded-tl-[10px]"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <HiChevronLeft className="h-6 w-6" /> : <HiChevronRight className="h-6 w-6" />}
      </button>
      <header className={`flex h-12 ${isOpen ? "w-[294px]" : "w-[124px]"} items-center justify-between`}>
        <figure
          id="heads-logo"
          className={`flex ${isOpen ? "h-[36px] w-[209px]" : "ml-6 h-[48px] w-[53px]"} items-center justify-center overflow-hidden transition-all duration-300`}
        >
          <SVGIcon icon={isOpen ? HeadsLogoFullDark : HeadsLogoHalfDark} fill="" width="" height="" />
        </figure>
      </header>
      <section
        id="side-menu item-list"
        className={`mt-9 flex h-[93%] flex-col justify-between ${isOpen && isTextShow ? "w-[272px]" : "w-fit"}`}
      >
        <div>
          {sideMenuItems.map((item, index) => {
            return (
              <div
                id="item"
                className={`cursor-pointer rounded-[20px] p-6 ${selectedMenuItem === item ? "bg-secondary" : "hover:bg-[#ffffff1a]"} ${!isOpen && !isTextShow && "ml-1 flex h-[76px] w-[76px] justify-center"}`}
                key={index}
                onClick={() => onItemClick(item)}
              >
                <div className={`flex items-center gap-3 ${!isOpen && "justify-start"}`}>
                  <SVGIcon
                    icon={handleIconMapping(item)}
                    fill={selectedMenuItem === item ? dynamicColor.primary : "#fff"}
                    width="28"
                    height="28"
                  />
                  {isOpen && isTextShow && (
                    <p
                      className={`text-base ${selectedMenuItem === item ? "text-primary font-semibold" : "font-medium text-white"}`}
                    >
                      {t(`side_menu.${item.toLowerCase()}`)}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <LocaleDropdown isCms />
        <div
          id="logout"
          className={`mb-9 cursor-pointer rounded-[20px] p-6 hover:bg-[#ffffff1a] ${!isOpen && !isTextShow ? "ml-1 flex h-[76px] w-[76px] justify-center" : "w-[272px]"}`}
        >
          <div className={`flex items-center gap-3 ${!isOpen && "justify-start"}`} onClick={handleLogout}>
            <SVGIcon icon={LogoutIcon} fill={"#fff"} width="28" height="28" />
            {isOpen && isTextShow && <p className={`text-base font-medium text-white`}>{t("side_menu.logout")}</p>}
          </div>
        </div>
      </section>
    </div>
  );
};

const sideMenuItems = [
  "DASHBOARD",
  "EMPLOYEE_MANAGEMENT",
  "CLIENT",
  "COMMISSION_SCHEME",
  "LEAVE",
  "HOLIDAY",
  "PAYROLL",
];

export default SideMenu;
