import TitleButton from "@/components/buttons/TitleButton";
import { useTranslation } from "react-i18next";
import RoundButton from "@/components/buttons/RoundButton";
import { ReactComponent as BackIcon } from "@/assets/icons/svg/backIcon.svg";
import { ReactComponent as NextIcon } from "@/assets/icons/svg/nextIcon.svg";
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as CircleAddIcon } from "@/assets/icons/svg/circleAddIcon.svg";
import { ReactComponent as DatePickerIcon } from "@/assets/icons/svg/datePickerIcon.svg";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/svg/crossIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Checkbox, FloatingLabel, Label } from "flowbite-react";
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { useEffect, useState } from "react";
import CustomDatePicker from "@/components/pickers/CustomDatePicker";
import {
  formatDateByHyphen,
  formatDateBySlashYYYYMMDD,
  formatDateFromHyphenToSlash,
  getOrganisationCode,
} from "@/utils/functions";
import { EmploymentType, Gender, PayType, PositionChangeType, SalaryChangeType } from "@/types/enums";
import { Position, Salary } from "@/types/employee";
import { HiOutlineX } from "react-icons/hi";
import { createEmployee, getCommissionSchemeList } from "@/api";
import { CommissionSchemeListData } from "@/types/scheme";
import LoadingSpinner from "@/components/utils/LoadingSpinner";

interface CommissionScheme {
  id: number;
}

const CreateEmployee = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const queryParamsByTab = searchParams.get("tab");

  // console.log("location:", location);
  // console.log("params:", params);

  const initialPositionItem = {
    name: null,
    type: null,
    startDate: null,
    endDate: null,
    workHour: null,
    changeType: null,
    changeReason: null,
    remark: null,
  };

  const initialSalaryItem: Salary = {
    employee: {
      id: null,
    },
    amount: null,
    transportationAllowance: null,
    specialAllowance: null,
    payType: null,
    effectiveDate: null,
    changeType: null,
    changeReason: null,
    remark: null,
    mpfPercentageByEmployee: null,
    mpfPercentageByEmployer: null,
  };

  const initialCommissionSchemes: CommissionScheme[] = [];

  const initialCreateEmployeeBody = {
    code: null,
    name: null,
    nameEn: null,
    nameZh: null,
    hkid: null,
    phone: null,
    email: null,
    address: null,
    dob: null,
    gender: null,
    emergencyContactPerson: null,
    emergencyContactPhone: null,
    joinDate: null,
    annualLeave: null,
    sickLeave: null,
    commissionSchemes: initialCommissionSchemes,
    positions: [initialPositionItem],
    salaries: [initialSalaryItem],
  };

  const [createEmployeeData, setCreateEmployeeData] = useState(initialCreateEmployeeBody);
  const [isLoading, setIsLoading] = useState(false);
  console.log("createEmployeeData:", createEmployeeData);

  // fetch create employee
  const fetchCreateEmployee = async () => {
    setIsLoading(true);
    try {
      const res = await createEmployee(createEmployeeData);
      if (res) {
        setIsLoading(false);
        navigate(`/cms/${getOrganisationCode()}/employee-management?status=success`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // handle tabs and back / next button
  const tabData = ["personal_details", "position", "salary", "commission_scheme"];
  const handleNext = () => {
    const tabMapping: Record<string, string> = {
      personal_details: "position",
      position: "salary",
      salary: "commission_scheme",
    };

    if (queryParamsByTab && tabMapping[queryParamsByTab]) {
      const nextTab = tabMapping[queryParamsByTab];
      searchParams.set("tab", nextTab);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };
  const handleBack = () => {
    const tabMapping: Record<string, string> = {
      position: "personal_details",
      salary: "position",
      commission_scheme: "salary",
    };

    if (queryParamsByTab && tabMapping[queryParamsByTab]) {
      const nextTab = tabMapping[queryParamsByTab];
      searchParams.set("tab", nextTab);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  // normal input change
  const handleInputChange = (value: any, name: string) => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // position items
  const onAddPositionItemClick = () => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      positions: [...prevState.positions, initialPositionItem],
    }));
  };
  const onRemovePositionItemClick = (index: number) => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      positions: prevState.positions.filter((_, i) => i !== index),
    }));
  };
  const handlePositionValueChange = (index: number, field: string, value: string | number | null) => {
    const updatedPositions = createEmployeeData.positions.map((position, i) => {
      if (i === index) {
        return { ...position, [field]: value };
      }
      return position;
    });
    setCreateEmployeeData({
      ...createEmployeeData,
      positions: updatedPositions,
    });
  };

  // salary items
  const onAddSalaryItemClick = () => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      salaries: [...prevState.salaries, initialSalaryItem],
    }));
  };
  const onRemoveSalaryItemClick = (index: number) => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      salaries: prevState.salaries.filter((_, i) => i !== index),
    }));
  };
  const handleSalaryValueChange = (index: number, field: string, value: string | number | null) => {
    const updatedSalaries = createEmployeeData.salaries.map((salary, i) => {
      if (i === index) {
        return { ...salary, [field]: value };
      }
      return salary;
    });
    setCreateEmployeeData({
      ...createEmployeeData,
      salaries: updatedSalaries,
    });
  };

  //INPUT FOCUS:
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [selectedInputType, setSelectedInputType] = useState<string>("");

  //personal_details
  const [isDobDatePickerOpen, setIsDobDatePickerOpen] = useState<boolean>(false);

  const [isGenderDropdownOpen, setIsGenderDropdownOpen] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("");

  const [isJoinDatePickerOpen, setIsJoinDatePickerOpen] = useState<boolean>(false);

  //position
  const [isEmploymentTypeDropdownOpen, setIsEmploymentTypeDropdownOpen] = useState<boolean>(false);
  const [selectedEmploymentTypeIndex, setSelectedEmploymentTypeIndex] = useState<number>(-1);

  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [selectedStartDateIndex, setSelectedStartDateIndex] = useState<number>(-1);

  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedEndDateIndex, setSelectedEndDateIndex] = useState<number>(-1);

  const [isPositionChangeTypeDropdownOpen, setIsPositionChangeTypeDropdownOpen] = useState<boolean>(false);
  const [selectedPositionChangeTypeIndex, setSelectedPositionChangeTypeIndex] = useState<number>(-1);

  //salary
  const [isPayTypeDropdownOpen, setIsPayTypeDropdownOpen] = useState<boolean>(false);
  const [selectedPayTypeIndex, setSelectedPayTypeIndex] = useState<number>(-1);

  const [isEffectiveDatePickerOpen, setIsEffectiveDatePickerOpen] = useState<boolean>(false);
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null);
  const [selectedEffectiveDateIndex, setSelectedEffectiveDateIndex] = useState<number>(-1);

  const [isSalaryChangeTypeDropdownOpen, setIsSalaryChangeTypeDropdownOpen] = useState<boolean>(false);
  const [selectedSalaryChangeTypeIndex, setSelectedSalaryChangeTypeIndex] = useState<number>(-1);

  //select commission scheme
  const [isCommissionSchemeDropdownOpen, setIsCommissionSchemeDropdownOpen] = useState<boolean>(false);

  const handleInputFocus = (
    isFocus: boolean,
    type:
      | "dob"
      | "gender"
      | "joinDate"
      | "employment_type"
      | "start_date"
      | "end_date"
      | "position_change_type"
      | "pay_type"
      | "effective_date"
      | "salary_change_type"
  ) => {
    setIsInputFocused(isFocus);
    setSelectedInputType(type);
    if (type === "dob") {
      setIsDobDatePickerOpen(true);
    }
    if (type === "gender") {
      if (!isFocus) {
        setTimeout(() => {
          setIsGenderDropdownOpen(false);
        }, 100);
      }
      setIsGenderDropdownOpen(true);
    }
    if (type === "joinDate") {
      setIsJoinDatePickerOpen(true);
    }
    if (type === "employment_type") {
      if (!isFocus) {
        setTimeout(() => {
          setSelectedEmploymentTypeIndex(-1);
          setIsEmploymentTypeDropdownOpen(false);
        }, 100);
      }
      setIsEmploymentTypeDropdownOpen(true);
    }
    if (type === "start_date") {
      setIsStartDatePickerOpen(true);
    }
    if (type === "end_date") {
      setIsEndDatePickerOpen(true);
    }
    if (type === "position_change_type") {
      if (!isFocus) {
        setTimeout(() => {
          setSelectedPositionChangeTypeIndex(-1);
          setIsPositionChangeTypeDropdownOpen(false);
        }, 100);
      }
      setIsPositionChangeTypeDropdownOpen(true);
    }
    if (type === "pay_type") {
      if (!isFocus) {
        setTimeout(() => {
          setSelectedPayTypeIndex(-1);
          setIsPayTypeDropdownOpen(false);
        }, 100);
      }
      setIsPayTypeDropdownOpen(true);
    }
    if (type === "effective_date") {
      setIsEffectiveDatePickerOpen(true);
    }
    if (type === "salary_change_type") {
      if (!isFocus) {
        setTimeout(() => {
          setSelectedSalaryChangeTypeIndex(-1);
          setIsSalaryChangeTypeDropdownOpen(false);
        }, 100);
      }
      setIsSalaryChangeTypeDropdownOpen(true);
    }
  };
  // control date-pickers onClose when state changed
  useEffect(() => {
    setIsDobDatePickerOpen(false);
  }, [createEmployeeData.dob]);
  useEffect(() => {
    setIsJoinDatePickerOpen(false);
  }, [createEmployeeData.joinDate]);
  useEffect(() => {
    setSelectedStartDateIndex(-1);
    setIsStartDatePickerOpen(false);
  }, [startDate]);
  useEffect(() => {
    setSelectedEndDateIndex(-1);
    setIsEndDatePickerOpen(false);
  }, [endDate]);
  useEffect(() => {
    setSelectedEffectiveDateIndex(-1);
    setIsEffectiveDatePickerOpen(false);
  }, [effectiveDate]);

  // handle commission scheme list
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  const [commissionSchemes, setCommissionSchemes] = useState<any[]>([]);
  const [selectedSchemeName, setSelectedSchemeName] = useState<any[]>([]);
  const [limit, setLimit] = useState(20);
  const [commissionSchemeTotalCount, setCommissionSchemeTotalCount] = useState(0);

  const loadMoreData = () => {
    setLimit((prev) => prev + 20);
  };

  // Debounce the search input by 300ms
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  // Fetch commission schemes
  const fetchCommissionSchemes = async () => {
    try {
      const response = await getCommissionSchemeList(0, limit, "", [], "", "desc", debouncedSearchValue);
      setCommissionSchemes(response.data.data);
      setCommissionSchemeTotalCount(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching commission schemes:", error);
    }
  };

  useEffect(() => {
    fetchCommissionSchemes();
  }, [debouncedSearchValue, limit, isCommissionSchemeDropdownOpen]);

  // selection of commission schemes
  const toggleSchemeSelection = (scheme: CommissionSchemeListData) => {
    // handle scheme name
    setSelectedSchemeName((prev) => {
      const exist = prev.some((s) => s.id === scheme.id);
      let updatedName;
      if (exist) {
        updatedName = prev.filter((s) => s.id !== scheme.id);
      } else {
        updatedName = [...prev, { id: scheme.id, name: scheme.name }];
      }
      return updatedName;
    });
    // handle create employee data scheme id
    setCreateEmployeeData((prevData) => {
      const exists = prevData.commissionSchemes.some((s) => s.id === scheme.id);
      let updatedCommissionSchemes;
      if (exists) {
        updatedCommissionSchemes = prevData.commissionSchemes.filter((s) => s.id !== scheme.id);
      } else {
        updatedCommissionSchemes = [...prevData.commissionSchemes, { id: scheme.id }];
      }
      return {
        ...prevData,
        commissionSchemes: updatedCommissionSchemes,
      };
    });
  };
  // Function to check if a scheme is selected (checkbox)
  const isSchemeSelected = (scheme: { id: number }) => {
    return createEmployeeData.commissionSchemes.some((s) => s.id === scheme.id);
  };

  // handle HKID validation
  function validateHKID(hkid: string) {
    const regex = /^[A-Z]{1,2}\d{6}\([0-9A]\)$/;
    const cleanedHKID = hkid.trim().toUpperCase();
    return regex.test(cleanedHKID);
  }

  return (
    <div className="w-full">
      <TitleButton
        title={t("employee.create_employee")}
        onClick={() => navigate(`/cms/${params.organisationCode}/employee-management`)}
      />

      <header className="mt-6 flex w-full items-center justify-between">
        <section id="CreateEmployee-tabs" className="flex w-full items-center gap-6">
          {tabData.map((tab, index) => {
            return (
              <figure
                key={index}
                className={`w-fit py-3 text-sm font-semibold 
                  ${
                    queryParamsByTab === tab
                      ? "border-secondary text-primary cursor-pointer border-b-2"
                      : "border-lightgrey cursor-default text-[#71707180] hover:border-b-2"
                  } `}
              >
                {t(`employee.${tab}`)}
              </figure>
            );
          })}
        </section>
        <section id="CreateEmployee-action-buttons" className="flex w-fit items-center gap-3">
          {queryParamsByTab === "commission_scheme" && (
            <RoundButton
              text={t("common.save")}
              onClick={fetchCreateEmployee}
              width="max-content"
              height="48px"
              bgColor="bg-secondary"
              textColor="text-dark"
              fontWeight="600"
              px="6"
              py="3"
              gap="12px"
              borderRadius="12px"
              iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
              disabled={isLoading}
            />
          )}
          <RoundButton
            text={t("common.back")}
            onClick={handleBack}
            width="117px"
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={BackIcon} fill="#030623" width="24" height="24" />}
            disabled={queryParamsByTab === "personal_details" || isLoading}
          />
          <RoundButton
            text={t("common.next")}
            onClick={handleNext}
            width="max-content"
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={NextIcon} fill="#030623" width="24" height="24" />}
            disabled={queryParamsByTab === "commission_scheme"}
          />
        </section>
      </header>
      {queryParamsByTab === "personal_details" && (
        <main id="CreateEmployee-main personal-details" className="mt-9 flex w-full flex-col gap-6">
          <section className="flex w-full flex-wrap items-center gap-3">
            <FloatingLabel
              variant="filled"
              label={t("employee.employee_or_contract_id")}
              theme={floatingLabelTheme}
              className="min-w-[272px]"
              onChange={(e) => handleInputChange(e.target.value, "code")}
              value={createEmployeeData.code || ""}
            />
            <FloatingLabel
              variant="filled"
              label={t("employee.display_name")}
              theme={floatingLabelTheme}
              className="min-w-[272px]"
              onChange={(e) => handleInputChange(e.target.value, "name")}
              value={createEmployeeData.name || ""}
            />
            <FloatingLabel
              variant="filled"
              label={t("employee.name_zh")}
              theme={floatingLabelTheme}
              className="min-w-[272px]"
              onChange={(e) => handleInputChange(e.target.value, "nameZh")}
              value={createEmployeeData.nameZh || ""}
            />
            <FloatingLabel
              variant="filled"
              label={t("employee.name_en")}
              theme={floatingLabelTheme}
              className="min-w-[272px]"
              onChange={(e) => handleInputChange(e.target.value, "nameEn")}
              value={createEmployeeData.nameEn || ""}
            />
            <FloatingLabel
              variant="filled"
              label={t("employee.hkid")}
              theme={floatingLabelTheme}
              className="min-w-[272px]"
              onChange={(e) => handleInputChange(e.target.value, "hkid")}
              value={createEmployeeData.hkid || ""}
            />
          </section>
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.contact_number")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange(e.target.value, "phone")}
                value={createEmployeeData.phone || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.email_address")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange(e.target.value, "email")}
                value={createEmployeeData.email || ""}
              />
            </div>
          </section>
          <section className="w-full">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.address")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange(e.target.value, "address")}
                value={createEmployeeData.address || ""}
              />
            </div>
          </section>
          <section className="flex w-full items-center gap-3">
            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.date_of_birth")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => handleInputFocus(true, "dob")}
                onBlur={() => handleInputFocus(false, "dob")}
                value={formatDateBySlashYYYYMMDD(createEmployeeData.dob)}
              />
              <figure
                className="hover-scale-sm absolute right-[12px] top-[25%] cursor-pointer"
                onClick={() => setIsDobDatePickerOpen(!isDobDatePickerOpen)}
              >
                <SVGIcon
                  icon={DatePickerIcon}
                  fill={isInputFocused && selectedInputType === "dob" ? "#16E6ED" : "#717071B3"}
                  width="24"
                  height="24"
                />
              </figure>
              {isDobDatePickerOpen && (
                <figure className="absolute right-0 top-12">
                  <CustomDatePicker
                    onSelectedDate={(value) => {
                      handleInputChange(formatDateByHyphen(value), "dob");
                    }}
                    onClose={() => setIsDobDatePickerOpen(false)}
                    isDob
                  />
                </figure>
              )}
            </div>

            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.gender")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => handleInputFocus(true, "gender")}
                onBlur={() => handleInputFocus(false, "gender")}
                value={gender ? t(`employee.${gender}`) : ""}
              />
              <figure className="absolute right-[12px] top-[25%]">
                <SVGIcon
                  icon={isInputFocused && selectedInputType === "gender" ? TriangleArrowUpIcon : TriangleArrowDownIcon}
                  fill="#717071"
                  width="24"
                  height="24"
                />
              </figure>
              {isGenderDropdownOpen && (
                <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                  {feGenderData.map((gender, index) => {
                    return (
                      <div
                        key={index}
                        className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                        onClick={() => {
                          handleInputChange(gender.value, "gender");
                          setGender(gender.placeholder);
                          setIsGenderDropdownOpen(false);
                        }}
                      >
                        {t(`employee.${gender.placeholder}`)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="relative w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.join_date")}
                theme={floatingLabelTheme}
                className="cursor-pointer"
                readOnly
                onFocus={() => handleInputFocus(true, "joinDate")}
                onBlur={() => handleInputFocus(false, "joinDate")}
                value={formatDateBySlashYYYYMMDD(createEmployeeData.joinDate)}
              />
              <figure
                className="hover-scale-sm absolute right-[12px] top-[25%] cursor-pointer"
                onClick={() => setIsJoinDatePickerOpen(!isJoinDatePickerOpen)}
              >
                <SVGIcon
                  icon={DatePickerIcon}
                  fill={isInputFocused && selectedInputType === "joinDate" ? "#16E6ED" : "#717071B3"}
                  width="24"
                  height="24"
                />
              </figure>
              {isJoinDatePickerOpen && (
                <figure className="absolute right-0 top-12 z-50">
                  <CustomDatePicker
                    onSelectedDate={(value) => {
                      handleInputChange(formatDateByHyphen(value), "joinDate");
                    }}
                    onClose={() => setIsJoinDatePickerOpen(false)}
                  />
                </figure>
              )}
            </div>
          </section>
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.Emergency_contact_name")}
                theme={floatingLabelTheme}
                value={createEmployeeData.emergencyContactPerson || ""}
                onChange={(e) => handleInputChange(e.target.value, "emergencyContactPerson")}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.Emergency_contact_number")}
                theme={floatingLabelTheme}
                value={createEmployeeData.emergencyContactPhone || ""}
                onChange={(e) => handleInputChange(e.target.value, "emergencyContactPhone")}
              />
            </div>
          </section>
        </main>
      )}
      {queryParamsByTab === "position" && (
        <main id="CreateEmployee-main position">
          {createEmployeeData.positions.map((position, index) => {
            return (
              <section
                id={`position ${index + 1}`}
                className="flex w-full flex-col gap-6 border-b-[1px] border-[#71707180] pb-9 pt-4"
                key={index}
              >
                {createEmployeeData.positions.length > 1 && (
                  <div className="flex w-full justify-end">
                    <figure className="rotate-45 cursor-pointer" onClick={() => onRemovePositionItemClick(index)}>
                      <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
                    </figure>
                  </div>
                )}
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.position")}
                      theme={floatingLabelTheme}
                      value={position.name || ""}
                      onChange={(e) => handlePositionValueChange(index, "name", e.target.value)}
                    />
                  </div>
                  <div className="relative w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.employment_type")}
                      theme={floatingLabelTheme}
                      className="cursor-pointer"
                      readOnly
                      onFocus={() => {
                        handleInputFocus(true, "employment_type");
                        setSelectedEmploymentTypeIndex(index);
                      }}
                      onBlur={() => handleInputFocus(false, "employment_type")}
                      value={position.type ? t(`employee.${(position.type as string).toLowerCase()}`) : ""}
                    />
                    <figure className="absolute right-[12px] top-[25%]">
                      <SVGIcon
                        icon={
                          isInputFocused && selectedInputType === "employment_type"
                            ? TriangleArrowUpIcon
                            : TriangleArrowDownIcon
                        }
                        fill="#717071"
                        width="24"
                        height="24"
                      />
                    </figure>
                    {isEmploymentTypeDropdownOpen && index === selectedEmploymentTypeIndex && (
                      <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                        {feEmploymentType.map((type, i) => {
                          return (
                            <div
                              key={i}
                              className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                              onClick={() => {
                                handlePositionValueChange(index, "type", type);
                                setIsEmploymentTypeDropdownOpen(false);
                              }}
                            >
                              {t(`employee.${type.toLowerCase()}`)}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="relative w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.start_date")}
                      theme={floatingLabelTheme}
                      className="cursor-pointer"
                      readOnly
                      onFocus={() => {
                        handleInputFocus(true, "start_date");
                        setSelectedStartDateIndex(index);
                      }}
                      onBlur={() => handleInputFocus(false, "start_date")}
                      value={formatDateFromHyphenToSlash(position.startDate) || ""}
                    />
                    <figure
                      className="hover-scale-sm absolute right-[12px] top-[25%] cursor-pointer"
                      onClick={() => setIsStartDatePickerOpen(!isStartDatePickerOpen)}
                    >
                      <SVGIcon
                        icon={DatePickerIcon}
                        fill={isInputFocused && selectedInputType === "start_date" ? "#16E6ED" : "#717071B3"}
                        width="24"
                        height="24"
                      />
                    </figure>
                    {isStartDatePickerOpen && selectedStartDateIndex === index && (
                      <figure className="absolute right-0 top-12 z-50">
                        <CustomDatePicker
                          onSelectedDate={(value) => {
                            setStartDate(value);
                            handlePositionValueChange(index, "startDate", formatDateByHyphen(value));
                          }}
                          onClose={() => setIsStartDatePickerOpen(false)}
                        />
                      </figure>
                    )}
                  </div>
                  <div className="relative w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.end_date")}
                      theme={floatingLabelTheme}
                      className="cursor-pointer"
                      readOnly
                      onFocus={() => {
                        handleInputFocus(true, "end_date");
                        setSelectedEndDateIndex(index);
                      }}
                      onBlur={() => handleInputFocus(false, "end_date")}
                      value={formatDateFromHyphenToSlash(position.endDate) || ""}
                    />
                    <figure
                      className="hover-scale-sm absolute right-[12px] top-[25%] cursor-pointer"
                      onClick={() => setIsEndDatePickerOpen(!isEndDatePickerOpen)}
                    >
                      <SVGIcon
                        icon={DatePickerIcon}
                        fill={isInputFocused && selectedInputType === "end_date" ? "#16E6ED" : "#717071B3"}
                        width="24"
                        height="24"
                      />
                    </figure>
                    {isEndDatePickerOpen && selectedEndDateIndex === index && (
                      <figure className="absolute right-0 top-12 z-50">
                        <CustomDatePicker
                          onSelectedDate={(value) => {
                            setEndDate(value);
                            handlePositionValueChange(index, "endDate", formatDateByHyphen(value));
                          }}
                          onClose={() => setIsEndDatePickerOpen(false)}
                        />
                      </figure>
                    )}
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.working_hour")}
                      theme={floatingLabelTheme}
                      value={position.workHour ?? ""}
                      onChange={(e) =>
                        handlePositionValueChange(
                          index,
                          "workHour",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <div className="relative w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.change_type")}
                      theme={floatingLabelTheme}
                      className="cursor-pointer"
                      readOnly
                      onFocus={() => {
                        handleInputFocus(true, "position_change_type");
                        setSelectedPositionChangeTypeIndex(index);
                      }}
                      onBlur={() => handleInputFocus(false, "position_change_type")}
                      value={position.changeType ? t(`employee.${(position.changeType as string).toLowerCase()}`) : ""}
                    />
                    <figure className="absolute right-[12px] top-[25%]">
                      <SVGIcon
                        icon={
                          isInputFocused && selectedInputType === "position_change_type"
                            ? TriangleArrowUpIcon
                            : TriangleArrowDownIcon
                        }
                        fill="#717071"
                        width="24"
                        height="24"
                      />
                    </figure>
                    {isPositionChangeTypeDropdownOpen && selectedPositionChangeTypeIndex === index && (
                      <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                        {fePositionChangeType.map((type, i) => {
                          return (
                            <div
                              key={i}
                              className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                              onClick={() => {
                                handlePositionValueChange(index, "changeType", type);
                                setIsPositionChangeTypeDropdownOpen(false);
                              }}
                            >
                              {t(`employee.${type.toLowerCase()}`)}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.reason_for_change")}
                      theme={floatingLabelTheme}
                      value={position.changeReason ?? ""}
                      onChange={(e) => handlePositionValueChange(index, "changeReason", e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.remark")}
                      theme={floatingLabelTheme}
                      value={position.remark ?? ""}
                      onChange={(e) => handlePositionValueChange(index, "remark", e.target.value)}
                    />
                  </div>
                </section>
              </section>
            );
          })}

          <button
            id="add-position-btn-container"
            className="hover-scale-sm mt-9 flex h-12 w-full items-center justify-center gap-3 rounded-xl bg-[#16E6ED1A] px-6 py-3"
            onClick={onAddPositionItemClick}
          >
            <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
            <p className="text-sm font-semibold text-[#16E6ED]">{t("employee.add_position")}</p>
          </button>
        </main>
      )}
      {queryParamsByTab === "salary" && (
        <main id="CreateEmployee-main salary">
          {createEmployeeData.salaries.map((salary, index) => {
            return (
              <section
                id={`salary ${index + 1}`}
                className="flex w-full flex-col gap-6 border-b-[1px] border-[#71707180] pb-9 pt-4"
                key={index}
              >
                {createEmployeeData.salaries.length > 1 && (
                  <div className="flex w-full justify-end">
                    <figure className="rotate-45 cursor-pointer" onClick={() => onRemoveSalaryItemClick(index)}>
                      <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
                    </figure>
                  </div>
                )}
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.salary")}
                      theme={floatingLabelTheme}
                      value={salary.amount ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "amount",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.transportation_allowance")}
                      theme={floatingLabelTheme}
                      value={salary.transportationAllowance ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "transportationAllowance",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.special_allowance")}
                      theme={floatingLabelTheme}
                      value={salary.specialAllowance ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "specialAllowance",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="relative w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.pay_type")}
                      theme={floatingLabelTheme}
                      className="cursor-pointer"
                      readOnly
                      onFocus={() => {
                        handleInputFocus(true, "pay_type");
                        setSelectedPayTypeIndex(index);
                      }}
                      onBlur={() => handleInputFocus(false, "pay_type")}
                      value={salary.payType ? t(`date.${salary.payType.toLowerCase()}`) : ""}
                    />
                    <figure className="absolute right-[12px] top-[25%]">
                      <SVGIcon
                        icon={
                          isInputFocused && selectedInputType === "pay_type"
                            ? TriangleArrowUpIcon
                            : TriangleArrowDownIcon
                        }
                        fill="#717071"
                        width="24"
                        height="24"
                      />
                    </figure>
                    {isPayTypeDropdownOpen && selectedPayTypeIndex === index && (
                      <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                        {feSalaryPayType.map((type, i) => {
                          return (
                            <div
                              key={i}
                              className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                              onClick={() => {
                                setIsPayTypeDropdownOpen(false);
                                handleSalaryValueChange(index, "payType", type);
                              }}
                            >
                              {t(`date.${type.toLowerCase()}`)}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="relative w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.effective_date")}
                      theme={floatingLabelTheme}
                      className="cursor-pointer"
                      readOnly
                      onFocus={() => {
                        handleInputFocus(true, "effective_date");
                        setSelectedEffectiveDateIndex(index);
                      }}
                      onBlur={() => handleInputFocus(false, "effective_date")}
                      value={formatDateFromHyphenToSlash(salary.effectiveDate) || ""}
                    />
                    <figure
                      className="hover-scale-sm absolute right-[12px] top-[25%] cursor-pointer"
                      onClick={() => setIsEffectiveDatePickerOpen(!isEffectiveDatePickerOpen)}
                    >
                      <SVGIcon
                        icon={DatePickerIcon}
                        fill={isInputFocused && selectedInputType === "effective_date" ? "#16E6ED" : "#717071B3"}
                        width="24"
                        height="24"
                      />
                    </figure>
                    {isEffectiveDatePickerOpen && selectedEffectiveDateIndex === index && (
                      <figure className="absolute right-0 top-12 z-50">
                        <CustomDatePicker
                          onSelectedDate={(value) => {
                            setEffectiveDate(value);
                            handleSalaryValueChange(index, "effectiveDate", formatDateByHyphen(value));
                          }}
                          onClose={() => setIsEffectiveDatePickerOpen(false)}
                        />
                      </figure>
                    )}
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.employee_mpf_percentage")}
                      theme={floatingLabelTheme}
                      value={salary.mpfPercentageByEmployee ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "mpfPercentageByEmployee",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.employer_mpf_percentage")}
                      theme={floatingLabelTheme}
                      value={salary.mpfPercentageByEmployer ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "mpfPercentageByEmployer",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="relative w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.salary_change_type")}
                      theme={floatingLabelTheme}
                      className="cursor-pointer"
                      readOnly
                      onFocus={() => {
                        handleInputFocus(true, "salary_change_type");
                        setSelectedSalaryChangeTypeIndex(index);
                      }}
                      onBlur={() => handleInputFocus(false, "salary_change_type")}
                      value={salary.changeType ? t(`employee.${salary.changeType.toLowerCase()}`) : ""}
                    />
                    <figure className="absolute right-[12px] top-[25%]">
                      <SVGIcon
                        icon={
                          isInputFocused && selectedInputType === "salary_change_type"
                            ? TriangleArrowUpIcon
                            : TriangleArrowDownIcon
                        }
                        fill="#717071"
                        width="24"
                        height="24"
                      />
                    </figure>
                    {isSalaryChangeTypeDropdownOpen && selectedSalaryChangeTypeIndex === index && (
                      <div className="absolute right-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow">
                        {feSalaryChangeType.map((type, i) => {
                          return (
                            <div
                              key={i}
                              className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                              onClick={() => {
                                setIsSalaryChangeTypeDropdownOpen(false);
                                handleSalaryValueChange(index, "changeType", type);
                              }}
                            >
                              {t(`employee.${type.toLowerCase()}`)}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.reason_for_change")}
                      theme={floatingLabelTheme}
                      value={salary.changeReason ?? ""}
                      onChange={(e) => handleSalaryValueChange(index, "changeReason", e.target.value)}
                    />
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.remark")}
                      theme={floatingLabelTheme}
                      value={salary.remark ?? ""}
                      onChange={(e) => handleSalaryValueChange(index, "remark", e.target.value)}
                    />
                  </div>
                </section>
              </section>
            );
          })}
          <button
            id="add-salary-btn-container"
            className="hover-scale-sm mt-9 flex h-12 w-full items-center justify-center gap-3 rounded-xl bg-[#16E6ED1A] px-6 py-3"
            onClick={onAddSalaryItemClick}
          >
            <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
            <p className="text-sm font-semibold text-[#16E6ED]">{t("employee.add_salary")}</p>
          </button>
        </main>
      )}
      {queryParamsByTab === "commission_scheme" && (
        <main id="CreateEmployee-main commission_scheme" className="relative mt-9 flex w-full flex-col gap-6">
          {isLoading && <LoadingSpinner />}
          <p className="text-dark w-full text-sm font-semibold">{t("employee.commission_scheme")}</p>

          <section id="commission_scheme" className="w-full">
            <figure
              className={`${isCommissionSchemeDropdownOpen && "border-secondary border"} relative w-full cursor-pointer rounded-lg bg-white p-3 shadow`}
              onClick={() => setIsCommissionSchemeDropdownOpen(true)}
            >
              <section className="flex w-full items-center">
                <figure className="w-full">
                  {selectedSchemeName.length > 0 ? (
                    <div
                      id="selected-commission_scheme-label-container"
                      className="flex w-full flex-wrap items-center gap-1.5"
                    >
                      {selectedSchemeName.map((scheme, index) => (
                        <figure
                          key={index}
                          id="selected-commission_scheme-label"
                          className="text-dark hover-scale-sm flex items-center justify-between gap-1.5 rounded-[3px] bg-[#0306230D] p-1.5 text-[10px] font-semibold"
                        >
                          <p className="w-full">{scheme.name}</p>
                          <figure
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleSchemeSelection(scheme);
                            }}
                          >
                            <HiOutlineX className="h-3 w-3 text-[#5F6368]" />
                          </figure>
                        </figure>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm font-medium text-[#717071B3]">{t("employee.select_commission_scheme")}</p>
                  )}
                </figure>
                <figure
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCommissionSchemeDropdownOpen(!isCommissionSchemeDropdownOpen);
                  }}
                >
                  <SVGIcon
                    icon={isCommissionSchemeDropdownOpen ? TriangleArrowUpIcon : TriangleArrowDownIcon}
                    fill="#717071"
                    width="24"
                    height="24"
                  />
                </figure>
              </section>
              {isCommissionSchemeDropdownOpen && (
                <section className="animate-fade-in-down absolute left-0 top-[110%] w-full rounded-lg bg-white p-3 shadow">
                  <section
                    id="search-commission_scheme"
                    className="flex w-full items-center gap-3 border-b-[1px] border-[#BDBDBD80] px-3 pb-3 pt-1.5"
                  >
                    <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
                    <input
                      placeholder={t("employee.search_by_commission_scheme")}
                      className="w-full text-sm font-medium  placeholder:text-[#717071B3] focus:outline-none"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <figure
                      className="hover-scale-sm cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCommissionSchemeDropdownOpen(false);
                      }}
                    >
                      <SVGIcon icon={CrossIcon} fill="#BDBDBD" width="24" height="24" />
                    </figure>
                  </section>

                  <section
                    id="commission_scheme-checkbox-container"
                    className="mt-3 max-h-[400px] min-h-fit overflow-scroll"
                  >
                    {commissionSchemes.map((scheme) => (
                      <figure
                        key={scheme.id}
                        id="commission_scheme-checkbox"
                        className="flex w-full items-center gap-3 rounded-lg px-3 hover:bg-[#0306230D]"
                      >
                        <Checkbox
                          id={`scheme-${scheme.id}`}
                          theme={checkboxTheme}
                          onChange={() => toggleSchemeSelection(scheme)}
                          checked={isSchemeSelected(scheme)}
                        />
                        <Label
                          htmlFor={`scheme-${scheme.id}`}
                          className="text-dark w-full cursor-pointer py-1.5 text-sm font-medium"
                        >
                          {scheme.name}
                        </Label>
                      </figure>
                    ))}
                    {commissionSchemeTotalCount > limit && (
                      <button
                        id="load-more-btn"
                        className="bg-secondary flex h-9 w-full items-center justify-center rounded-lg text-sm text-white"
                        onClick={loadMoreData}
                      >
                        <p>{t("common.more")}</p>
                      </button>
                    )}
                  </section>
                </section>
              )}
            </figure>
          </section>
        </main>
      )}
    </div>
  );
};

export default CreateEmployee;

const feGenderData = [
  { placeholder: "male", value: Gender.MALE },
  { placeholder: "female", value: Gender.FEMALE },
  { placeholder: "other", value: Gender.OTHER },
];
const feEmploymentType = [EmploymentType.FULL_TIME, EmploymentType.PART_TIME, EmploymentType.INTERN];
const fePositionChangeType = [PositionChangeType.PROMOTION, PositionChangeType.TRANSFER];
const feSalaryPayType = [PayType.ANNUALLY, PayType.MONTHLY, PayType.WEEKLY, PayType.DAILY, PayType.HOURLY];
const feSalaryChangeType = [SalaryChangeType.NORMAL, SalaryChangeType.PROMOTION];
const checkboxTheme = {
  root: {
    base: "h-4 w-4 rounded border border-secondary bg-white focus:ring-0 cursor-pointer",
    color: {
      default: "text-[#16E6ED]",
    },
  },
};
