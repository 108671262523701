import { setApiError } from "@/redux/setting/settingSlice";
import store from "@/redux/store";
import axios from "axios";

const currentUrl = window.location.href;
const urlObj = new URL(currentUrl);
const pathname = urlObj.pathname;
const organisationCode = pathname.split("/")[2];

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //   timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  if (organisationCode) {
    localStorage.setItem("organisationCode", organisationCode);
    if (localStorage.getItem("organisationCode") !== organisationCode) {
      localStorage.setItem("organisationCode", organisationCode);
    }
    config.baseURL = config.baseURL + `/${organisationCode}`;
  }
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      console.error("api response error:", error.response);
      store.dispatch(setApiError(error.response.data));
      if (error.response.status === 404 && pathname.split("/")[3] === "login") {
        localStorage.removeItem("token");
        return;
      }
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        if (pathname.split("/")[3] === "login") {
          return;
        }
        window.open(`/cms/${localStorage.getItem("organisationCode")}/login`, "_self");
      }
    }
    return Promise.reject(error);
  }
);

export { api };
