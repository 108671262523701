import { useEffect, useState } from "react";
import { login } from "@/api/auth/auth.api";
import { capitalizeFirstLetter, handleCaughtError } from "@/utils/functions";
import ErrorMessage from "@/components/utils/ErrorMessage";
import { Checkbox, Label } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import FetchLoader from "@/components/utils/FetchLoader";
import { useAppSelector } from "@/redux/store";
import LoadingSpinner from "@/components/utils/LoadingSpinner";

type UserInputValue = {
  username: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { apiError } = useAppSelector((state) => state.setting);

  const [isRememberMe, setIsRememberMe] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>("");

  // handle input change
  const [userInputValue, setUserInputValue] = useState<UserInputValue>({ username: "", password: "" });
  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue({
      ...userInputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await login(userInputValue.username, userInputValue.password);
      if (response?.data.access_token && params.organisationCode) {
        localStorage.setItem("token", response.data.access_token);
        navigate(`/cms/${params.organisationCode}/dashboard`);
      }
      setIsLoading(false);
    } catch (error) {
      const caughtError = handleCaughtError(error);
      console.error("login ERROR:", caughtError);
      setIsLoading(false);
      setErrorMessage(caughtError ?? apiError.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate(`/cms/${params.organisationCode}/dashboard`);
    }
  }, []);

  useEffect(() => {
    setErrorMessage(apiError.message);
  }, [apiError]);

  return (
    <div className="relative flex h-[100vh] w-full items-center justify-center">
      {isLoading && <LoadingSpinner />}
      <form onSubmit={handleSubmit}>
        <main className="border-primary h-fit w-[700px] border p-8">
          <header className="text-dark flex w-full items-center justify-center">
            <h1>{`${capitalizeFirstLetter(params.organisationCode)} ${t("common.management_system")}`}</h1>
          </header>
          <section className="mt-8 flex w-full flex-col gap-8">
            <div className="flex items-center gap-3">
              <label className="text-dark min-w-[100px]">{t("auth.username")}</label>
              <input
                type="text"
                id="username"
                name="username"
                value={userInputValue.username}
                onChange={handleUserInputChange}
                className="focus:border-primary w-full focus:outline-none"
              />
            </div>
            <div className="flex items-center gap-3">
              <label className="text-dark min-w-[100px]">{t("auth.password")}</label>
              <input
                type="password"
                id="password"
                name="password"
                value={userInputValue.password}
                onChange={handleUserInputChange}
                className="focus:border-primary w-full focus:outline-none"
              />
            </div>
          </section>
          <div className="mt-8 flex items-center gap-2">
            <Checkbox
              id="remember"
              theme={checkboxTheme}
              defaultChecked
              onChange={(e) => setIsRememberMe(e.target.checked)}
            />
            <Label htmlFor="remember" className="text-dark">
              {t("auth.remember_me")}
            </Label>
          </div>
          <button type="submit" className="bg-primary mt-[24px] w-full py-2 text-white hover:opacity-75">
            {t("common.submit")}
          </button>
          <ErrorMessage message={errorMessage} />
        </main>
      </form>
    </div>
  );
};

export default Login;

const checkboxTheme = {
  root: {
    base: "h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-0 focus:outline-none",
    color: {
      default: "text-primary ",
    },
  },
};
