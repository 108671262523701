export enum LeaveType {
  ANNUAL_LEAVE = "ANNUAL_LEAVE",
  NO_PAY_LEAVE = "NO_PAY_LEAVE",
  SICK_LEAVE = "SICK_LEAVE",
  NO_PAY_SICK_LEAVE = "NO_PAY_SICK_LEAVE",
  MATERNITY_LEAVE = "MATERNITY_LEAVE",
  INJURY_LEAVE = "INJURY_LEAVE",
  BIRTHDAY_LEAVE = "BIRTHDAY_LEAVE",
}

export enum LeaveStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
}
