import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as CreateEmployeeIcon } from "@/assets/icons/svg/createEmployeeIcon.svg";
import { ReactComponent as TableSortIcon } from "@/assets/icons/svg/tableSortIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Avatar, Badge, Table } from "flowbite-react";
import userDefaultAvatar from "@/assets/defaults/userDefaultAvatar.png";
import { useTranslation } from "react-i18next";
import Pagination from "@/components/utils/Pagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SortColumn } from "@/types/employee";
import {
  formatDateFromHyphenToSlash,
  getQueryParam,
  handleCaughtError,
  snakeCaseToCamelCase,
  updateQueryParam,
} from "@/utils/functions";
import { SortOrder } from "@/types/util";
import { getEmployeeList } from "@/api";
import NotificationToast from "@/components/toasts/NotificationToast";

const EmployeeManagement = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParamStatus = getQueryParam("status");

  const tableHeadCells = [
    "employee",
    "email_address",
    "position",
    "employment_type",
    "basic_salary",
    "join_date",
    "action",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [currentSortColumn, setCurrentSortColumn] = useState<SortColumn>("");
  const [currentSortOrder, setCurrentSortOrder] = useState<SortOrder>("desc");

  // fetch get employee list
  const [getEmployeeListResponse, setGetEmployeeListResponse] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  console.log("getEmployeeListResponse:", getEmployeeListResponse);

  const fetchGetEmployeeList = async () => {
    const page = currentPage - 1;
    const sortColumn = snakeCaseToCamelCase(currentSortColumn);
    try {
      const response = await getEmployeeList(page, currentLimit, sortColumn, currentSortOrder, currentSearchValue);
      if (response) {
        setGetEmployeeListResponse(response.data);
      }
    } catch (error) {
      console.error(error);
      updateQueryParam("status", "error");
      setErrorMessage(handleCaughtError(error));
    }
  };

  // Debounce logic for the search value
  const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(currentSearchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [currentSearchValue]);

  // Fetch employee list whenever page, limit, sort, or debounced search value changes
  useEffect(() => {
    fetchGetEmployeeList();
  }, [currentPage, currentLimit, currentSortColumn, currentSortOrder, debouncedSearchValue, i18n.language]);

  // handle convert gender value
  const convertGender = (value: string) => {
    const map: Record<string, string> = {
      M: "male",
      F: "female",
      "N/A": "other",
    };
    return map[value];
  };

  return (
    <div id="employee-management-main" className="h-full w-full">
      {queryParamStatus && (
        <NotificationToast
          message={queryParamStatus === "success" ? t("commission_scheme.saved_success_message") : errorMessage || ""}
        />
      )}
      <header id="employee-management-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={(value) => setCurrentSearchValue(value)} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          width="118px"
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("employee.create_employee")}
          onClick={() => navigate(`${location.pathname}/create-employee?tab=personal_details`)}
          width="200px"
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CreateEmployeeIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="mt-6 overflow-x-auto">
        <Table theme={theme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <Table.HeadCell key={index} className="bg-primary text-base font-medium text-white">
                  <div className="flex items-center gap-2">
                    <p>{t(`employee.${tableHeadCell}`)}</p>
                    {tableHeadCell !== "action" && (
                      <figure className="hover-scale-sm cursor-pointer">
                        <SVGIcon icon={TableSortIcon} fill="#ffffff" width="24" height="24" />
                      </figure>
                    )}
                  </div>
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            {getEmployeeListResponse?.data?.map((res: any, index: number) => (
                <Table.Row key={index} className="text-dark h-[83px] w-full border-b border-b-[#71707180] bg-white text-sm font-medium">
                  <Table.Cell id="employee-info" className="whitespace-nowrap">
                    <div className="flex items-start gap-3">
                      <Avatar img={userDefaultAvatar} className="h-9 w-9 overflow-hidden rounded-full" />
                      <section id="employee-name-id">
                        <p>{`${res.nameEn} ${res.name}`}</p>
                        <p className="text-grey text-xs">{res.code}</p>
                      </section>
                      <Badge className="h-6 w-fit rounded-[6px] bg-[#FF006E26] text-[10px] text-[#FF006E]">
                        {t(`employee.${convertGender(res.gender)}`)}
                      </Badge>
                    </div>
                  </Table.Cell>
                  <Table.Cell id="email">{res.email}</Table.Cell>
                  <Table.Cell id="role">{res.position}</Table.Cell>
                  <Table.Cell id="employee-type">{t(`employee.${res.employmentType.toLowerCase()}`)}</Table.Cell>
                  <Table.Cell id="salary">{res.salary}</Table.Cell>
                  <Table.Cell id="join-date">{formatDateFromHyphenToSlash(res.joinDate)}</Table.Cell>
                  <Table.Cell id="action">
                    <Badge className="hover-scale-sm flex h-9 w-[105px] cursor-pointer items-center justify-center rounded-[6px] bg-[#8338EC26] text-sm font-medium text-[#8338EC]">
                      {t("common.view_details")}
                    </Badge>
                  </Table.Cell>
                </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        <Pagination
          currentPage={currentPage}
          totalPages={getEmployeeListResponse?.totalPages}
          totalCount={getEmployeeListResponse?.totalCount}
          onPageChange={(page: number) => setCurrentPage(page)}
          onLimitChange={(limit: number) => setCurrentLimit(limit)}
        />
      </footer>
    </div>
  );
};
export default EmployeeManagement;

const theme = {
  root: {
    base: "w-full text-left text-sm text-gray-500",
    shadow: "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md",
    wrapper: "relative",
  },
  body: {
    base: "group/body",
    cell: {
      base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
    },
  },
  head: {
    base: "group/head text-xs text-gray-700",
    cell: {
      base: "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:first:rounded-bl-xl group-first/head:last:rounded-tr-xl group-first/head:last:rounded-br-xl",
    },
  },
  row: {
    base: "group/row",
    hovered: "hover:bg-gray-50",
    striped: "odd:bg-white even:bg-gray-50",
  },
};
