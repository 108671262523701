import { api } from "@/api/apiClient";
import { CreateScheme, UpdateScheme } from "@/types/scheme";
import { SortOrder } from "@/types/util";

export const getScheme = (id: number) => {
  return api.get(`/scheme/${id}`);
};

export const getCommissionSchemeList = (
  page: number,
  limit: number,
  name: string,
  employeeIds: number[],
  sortColumn: string,
  sortOrder: SortOrder,
  searchValue: string
) => {
  return api.get(`/commission-scheme`, {
    params: {
      page,
      limit,
      name,
      employeeIds,
      sortColumn,
      sortOrder: sortOrder ?? "desc",
      searchValue,
    },
  });
};

export const createScheme = (data: CreateScheme) => {
  return api.post(`/scheme`, data);
};

export const updateScheme = (id: number, data: UpdateScheme) => {
  return api.patch(`/scheme/${id}`, data);
};

export const deleteScheme = (id: number) => {
  return api.delete(`/scheme/${id}`);
};
