import React from "react";

const SVGIcon = ({ icon, fill, width, height }) => {
   const IconComponent = icon;
   const isLocalSVG = React.isValidElement(icon);

   const renderIcon = () => {
      if (isLocalSVG) {
         const LocalIconComponent = icon;
         return <LocalIconComponent />;
      } else {
         return <img src={icon} alt="SVG from API" />;
      }
   };

   return (
      <svg
         width={width}
         height={height}
         fill={fill}
         viewBox={`0 0 ${width} ${height}`}
         xmlns="http://www.w3.org/2000/svg">
         <IconComponent />
      </svg>
   );
};

export default SVGIcon;
