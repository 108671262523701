import { CaughtError } from "@/types/util";
import { parseISO, format } from "date-fns";
import { t } from "i18next";

// Error handler
export const handleCaughtError = (error: any) => {
  const caughtError = error as CaughtError;

  if (caughtError.data) {
    return caughtError.data.message;
  }
  if (caughtError.response && caughtError.response.data) {
    return caughtError.response.data.message;
  }

  if (caughtError.status === 500 || !caughtError.data) {
    return t("error.sorry_something_went_wrong");
  }
};

// "2024-04-17T00:00:00.000Z" to "2024-04-17 00:00"(+8)
export const formatDateTime = (dateString: string | null) => {
  if (!dateString) {
    return "";
  }
  const date = parseISO(dateString);
  const formattedDate = format(date, "yyyy-MM-dd HH:mm");
  return formattedDate;
};

// "2024-04-17T00:00:00.000Z" to "2024-04-17"(+8)
export const formatDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const date = parseISO(dateString);
  const formattedDate = format(date, "yyyy-MM-dd");
  return formattedDate;
};

// "2024-04-17T00:00:00.000Z GMT+0800" to "2024-04-17"
export const formatDateByHyphen = (dateString: string | null | Date) => {
  if (!dateString) {
    return null;
  }
  const formattedDate = format(dateString, "yyyy-MM-dd");
  return formattedDate;
};

// "2024-04-17T00:00:00.000Z" to "17/04/2024"(+8)
export const formatDateBySlashDDMMYYYY = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const date = parseISO(dateString);
  const formattedDate = format(date, "dd/MM/yyyy");
  return formattedDate;
};

// "2024-04-17T00:00:00.000Z GMT+0800" to "2024/04/17"
export const formatDateBySlashYYYYMMDD = (dateString: string | null | Date) => {
  if (!dateString) {
    return "";
  }
  const formattedDate = format(dateString, "yyyy/MM/dd");
  return formattedDate;
};

// "1997-05-04" to "1997/05/04"
export const formatDateFromHyphenToSlash = (dateString: string | null) => {
  if (!dateString) {
    return null;
  }
  const date = parseISO(dateString);
  const formattedDate = format(date, "yyyy/MM/dd");
  return formattedDate;
};

// 1997-05-04T00:00:00
export const getCurrentDateTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

// Convert Bytes to KB or MB
export const convertBytesToKBorMB = (bytes: number): string => {
  if (bytes >= 1_000_000) {
    return `${(bytes / 1_000_000).toFixed(2)} MB`;
  }
  return `${(bytes / 1_000).toFixed(2)} KB`;
};

// Get Organisation Code from URL
export const getOrganisationCode = () => {
  const currentUrl = window.location.href;
  const urlObj = new URL(currentUrl);
  const pathname = urlObj.pathname;
  const organisationCode = pathname.split("/")[2];
  return organisationCode;
};

// Convert Hyphens To Underscores
export const convertHyphensToUnderscores = (input: string) => {
  if (input.includes("-")) {
    return input.replace(/-/g, "_");
  }
  return input;
};

// capitalize first letter (kenneth > Kenneth)
export const capitalizeFirstLetter = (string: string | undefined) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// snake case to camel case (employment_type > employmentType)
export const snakeCaseToCamelCase = (str: string) => {
  if (!str || !str.includes("_")) {
    return str;
  }
  return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
};

// update query param
export const updateQueryParam = (key: string, value: string) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(key, value);
  window.history.pushState({}, "", currentUrl.href);
};

// get query param
export const getQueryParam = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};
