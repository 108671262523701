import { LanguageType } from "@/types/enums";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Language = { id: number; name: string; lang: LanguageType }[];

export const languagesData: Language = [
  { id: 1, name: "繁中", lang: LanguageType.ZH_HK },
  { id: 2, name: "简中", lang: LanguageType.ZH_CN },
  { id: 3, name: "Eng", lang: LanguageType.EN },
];

const initialState = {
  onNavbarMouseLeave: false,
  dynamicColor: {
    primary: "#000000",
    secondary: "#000000",
  },
  apiError: {
    error: "",
    message: "",
    statusCode: 200,
  },
  isSideMenuToggle: true,
};

const settingsSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setOnNavbarMouseLeave(state, action) {
      state.onNavbarMouseLeave = action.payload;
    },
    setDynamicColor(state, action) {
      state.dynamicColor = action.payload;
    },
    setApiError(state, action) {
      state.apiError = action.payload;
    },
    setIsSideMenuToggle(state, action) {
      state.isSideMenuToggle = action.payload;
    },
  },
});

export const { setOnNavbarMouseLeave, setDynamicColor, setApiError, setIsSideMenuToggle } = settingsSlice.actions;

export default settingsSlice.reducer;
